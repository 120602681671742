import { Injectable } from '@angular/core';
import { Events } from '@ionic/angular';
import { EventHandler } from '@ionic/angular/dist/providers/events';
import { Logger } from './logger.service';
import { ProviderWithInit } from './api/provider-with-init';
const log = new Logger('AppEventsService');
@Injectable({
  providedIn: 'root'
})
export class AppEventsService extends ProviderWithInit{

  public event = {
		APP_READY: 'Application.ready',
		USER_SETTINGS_LOADED: "UserSettings.settingsLoaded",
		REFRESH_DONE: 'RefreshPage.refreshDone',
		NEED_REFRESH: 'RefresshPage.needRefreshData',
		NEED_UPDATE: 'UpdatePage.needUpdate',
		NETWORK_DISCONNECTED: 'Network.disconnected',
		NETWORK_CONNECTED: "Network.connected",
		API_CALL_WHER_NETWORK_DISCONNECTED: 'Api.no network error', 
		CHANGE_LANGUAGE: 'AppConfig.changeLanguage',		
		API_ERROR_500: 'Internal_Server_Error',
		USER_OP_ERROR: 'User.Operation_Failed',
		USER_OP_SUCCESS: 'User.Operation_Success',
		API_ERROR_NO_VALID_DATA: 'API.error.nodata',
		UKNOWN_ERROR: 'UnknownError',
		NAVIGATION_SET_PAGE: 'Navigation.SetPage',
		USER_LOGIN: 'User.Login',
		USER_LOGOUT: 'User.Logout',
		USER_CHANGE_LOGIN_STATUS: 'User.ChangeLoginStatus',
		USER_SETTINGS_CHANGED: 'User.ChangeSettings',
		USER_CHANGE_SESSIONS: "User.ChangeSessions",
		APPLICATION_READY: 'APP.Ready',
		APP_CONFIG_LOADED: 'AppConfig.Loaded',
		USER_CHANGE_PROFILE: 'User.ChangedProfile',
		USER_CLICK_BACK_BUTTON: "User.ClickBackButton",
		CHAT_MESSAGE_SENDED: 'Chat.MessageSended',
		CHAT_MESSAGE_RECEIVED: 'Chat.MessageReceived',
		PUSH_MESSAGE_RECEIVED: 'Push.MessageReceived',
    	IDLE_TIMEOUT: 'APP.UserIdleTimeout',
		TRANSLATION_INIT: 'Translation.init',
		APPLICATION_EVENT_CHANGED: 'Application.EventChanged',
		NEED_REBUILD_MENU: "Menu.NeedRebuilding",
		TAB_BAR_SET_TAB: "TabBar.SetTab"
	} 

	constructor(
		private events: Events
		){
		super();
		log.debug('constructor');
		this.ready();
	}
	
	protected async init(){
		log.info('ready: true');
	}
	/**
	 * Subskrypcja zdarzenia
	 * 
	 * @param  {string} eventName
	 * @param  {EventHandler} handler
	 */
	subscribe( eventName: string, handler: EventHandler ){
		log.debug("subscribe: event:" + eventName);
		this.events.subscribe ( eventName, handler );
	}
	/**
	 * Publikacja zdarzenia
	 * 
	 * @param  {string} eventName
	 * @param  {any=undefined} eventData
	 */
	publish( eventName: string, eventData: any = undefined){
		log.info("publish: event: " + eventName);
		this.events.publish( eventName, eventData );
	}
	/**
	 * Zakończenie subksrypcji zdarzenia
	 * 
	 * @param  {string} eventName
	 * @param  {EventHandler} handler
	 */
	unsubscribe( eventName: string, handler: EventHandler ){
		log.debug("unsubscribe: event: " + eventName);
		this.events.unsubscribe( eventName, handler );
	}
}
