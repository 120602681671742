import * as Moment from 'moment';
import { TokenResponseData } from './interfaces/token-response-data';


export class Token {

	private token: TokenResponseData;

	/* 
	*  bezpieczny margines czasu w sekundach 
	*  do określenia czasu wygaśnięcia tokena
	*  od momentu jego otrzymania
	*/
	private safeTimeMarginInSeconds = 300;


	/**
	 * @param  {TokenResponseData} token
	 * @param  {boolean=false} setExpireTime
	 */
	constructor(token: TokenResponseData, setExpireTime: boolean = false) {
		//todo sprawdz czy token jest poprawny
		this.token = token;
		if (setExpireTime) {
			let t = new Date();
			t.setSeconds(t.getSeconds() + token.expires_in - this.safeTimeMarginInSeconds);
			this.token.expire_time = t;
		}

	}


	/**
	 * Czy token jest ważny 
	 * 
	 * @returns boolean
	 */
	isValid(): boolean {
		if (this.token)
			if (this.token.access_token)
				if (this.token.expire_time)
					return (!Moment().isAfter(this.token.expire_time));
		return false;
	}
	/**
	 * Czy token nadaje się do odświeźenia
	 * @returns boolean
	 */
	canRefresh(): boolean {
		if (this.token)
			if (this.token.access_token)
				if (this.token.refresh_token)
					return true;
		return false;
	}

	/**
	 * Pobiera token
	 * 
	 * @returns TokenResponseData
	 */
	getToken(): TokenResponseData {
		return this.token;
	}
	/**
	 * Zwraca łańcuch znaków tokena
	 * @returns string
	 */
	getAccessTokenString(): string {
		if (!this.isValid())
			return undefined;
		return this.token.access_token;
	}
}