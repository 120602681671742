import { APIResponseData } from './interfaces/api-response-data';
import { Logger } from '../logger.service';
import { localeData } from 'moment';
const log = new Logger('ApiResponse');

/*
 * Odpowiedź API Serwera
 * 
 * @export
 * @class APIResponse
 */
export class APIResponse {

    private response: APIResponseData;


    constructor(response: APIResponseData) {
        if (
            (response === undefined) ||
            (((response.content === undefined) && (response.error === undefined)) || (response.code === undefined))
        ) {

            response = {
                success: 0,
                error: 'INVALID API RESPONSE',
                code: 401
            }
            log.warn('Trying to create empty APIResponse');
        }
        this.response = response;
    }


	/**
	 * Czy odpowiedź jest sukcesem (operacja wykonała się prawidłowo)
	 * 
	 * @returns {boolean} 
	 */
    isSuccess(): boolean {
        if (this.response)
            if (this.response.success)
                return true;
        return false;
    }

	/**
	 * Pobiera całą, nie przetworzoną odpowiedź serwera
	 * 
	 * @returns APIResponseData
	 */
    getResponse() {
        return this.response;
    }

	/**
	 * Pobiera przetworzoną odpowiedź serwera
	 * 
	 * @returns 
	 */
    getContent() {
        return this.response.content;
    }

	/**
	 * Ustawia nową zawartość odpowiedzi serwera
	 * 
	 * @param {any} newContent 
	 */
    setContent(newContent:any) {
        this.response.content = newContent;
    }

	/**
	 * PObiera listę błędów
	 * 
	 * @returns 
	 */
    getErrors() {
        return this.response.error;
    }
}