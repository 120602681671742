import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of, iif, throwError } from "rxjs";
import { concatMap, retryWhen, flatMap, delay, shareReplay } from "rxjs/operators";
import { Logger } from "../logger.service";
import { APIResponse } from "./api-response";
import { AppConfigService } from "../app-config.service";
import * as Moment from "moment";
import { APIResponseData } from "./interfaces/api-response-data";
import { cachedData } from "./interfaces/cached-data";

const log = new Logger("ApiCallService");
@Injectable({
  providedIn: "root"
})
export class ApiCallService {
  private memoryCache = [];
  private cacheTTL = 900;
  private storageType = "";

  constructor(private http: HttpClient, private appConfig: AppConfigService) {
    this.storageType = this.appConfig.appSettings.storage.STORAGE_FILES;
  }

  clearCache() {
    this.memoryCache = [];
    return this.appConfig.appSettings.storage.storageClear("api-calls", this.storageType);
  }

  /**
   * Ustawia domyślne nagłówki
   *
   * @param  {string=undefined} accessToken Token
   * @returns HttpHeaders
   */
  public getDefaultHeaders(accessToken: string = undefined): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append("Accept", "application/json");
    headers = headers.append("Content-Type", "application/json");

    if (accessToken != undefined) headers = headers.append("Authorization", "Bearer " + accessToken);
    return headers;
  }
  /**
   * Pobiera wynik zapytania api z cache'a
   *
   * @param  {string} apiClass
   * @param  {string} apiMethod
   * @param  {any={}} apiParams
   * @returns Promise
   */
  public async getOfflineCall(apiClass: string, apiMethod: string, apiParams: any = {}): Promise<APIResponse> {
    const key = await this.getCacheKey(apiClass, apiMethod, apiParams);
    log.debug("offlineCall url", key);
    if (this.memoryCache[key]) {
      log.debug("getOfflineCall:" + key + " load result from storage", this.memoryCache[key]);
      return this.memoryCache[key];
    }

    let result = await this.appConfig.appSettings.storage.storageGet("api-calls", key, this.storageType);
    log.debug("getOfflineCall:" + key + " load result from storage", result);
    return result;
  }
  /**
   * @param  {string} key
   * @param  {number} ttl
   * @returns Promise
   */
  private async loadFromStorage(key: string): Promise<cachedData> {
    let result: cachedData;
    try {
      result = await this.appConfig.appSettings.storage.storageGet("api-calls", key, this.storageType);
    } catch (error) {
      log.error("loadFromStorage: error", error, key);
      return undefined;
    }

    if (result && Moment().isAfter(result.expire_time)) {
      log.warn("loadFromStorage: cache data expired", key);
      return undefined;
    }

    if (result && (result.data.code != 200 || result.data.success != 1)) {
      log.error("loadFromStorage: caching Bad Request!!!", key);
      return undefined;
    }
    if (result) log.debug("loadFromStorage: cache data is valid", key);
    else log.debug("loadFromStorage: missing data in storage", key);
    return result;
  }

  /**
   * Wykonuje cachowalne zapytanie API
   * UWAGA! Funkcja dla uproszczenia struktury kodu zwraca Promise
   *
   * @param  {string} apiClass Klasa API
   * @param  {string} apiMethod Metoda klasy API
   * @param  {any={}} apiParams Dodatkowe parametry API
   * @param  {string} token Token autoryzujący
   * @param  {boolean=false} forceRequest Czy wymusić źądanie do serwera API, w przeciwnym razie jeśli istnieją to pobrane
   * są wcześniejsze wyniki z pamięci
   * @param  {boolean=true} storeInCache Czy zapisać (i odczytać) wyniki w trwałym cache'a (plikach telefonu/przeglądarki)
   * @param  {number=undefined} cacheTTL Czas w sekundach do wygaśnięcia danych z cache'a
   * @param  {any=[]} addHeaders Dodatkowe nagłówki
   * @param {boolean = true} ignoreLoadingBar
   * @returns Promise
   */
  public async cacheCall(
    apiClass: string,
    apiMethod: string,
    apiParams: any = {},
    token: string,
    forceRequest: boolean = false,
    storeInCache: boolean = true,
    cacheTTL: number = undefined,
    addHeaders: any = [],
    ignoreLoadingBar = true
  ): Promise<APIResponse> {
    log.debug(
      "cacheCall: forceRequest=" + forceRequest + ', apiClass="' + apiClass + ", apiMethod=" + apiMethod + ", token=" + token + ", addHeaders=", addHeaders
    );
    const key = await this.getCacheKey(apiClass, apiMethod, apiParams); 
    if (forceRequest){
      this.memoryCache[key] = undefined;
      if (storeInCache)
        await this.appConfig.appSettings.storage.storageSet("api-calls", key, undefined, this.storageType);
    }
    if (!this.memoryCache[key] || forceRequest) {
      if (!cacheTTL) cacheTTL = this.cacheTTL;
      let result: cachedData;
      if (storeInCache && !forceRequest) {
        result = await this.loadFromStorage(key);
        log.debug('cacheCall: loaded from storage');
      }
      if (!result) {
        let apiResponseData: APIResponseData;
        try {
          apiResponseData = await this.call(apiClass, apiMethod, apiParams, token, addHeaders, ignoreLoadingBar).toPromise();
        } catch (error) {
          log.error("cacheCall:" + apiMethod + " api call error=", error);
          return Promise.reject(error);
        }

        if (!apiResponseData || apiResponseData.success != 1 || apiResponseData.code != 200) {
          log.error("cacheCall:" + apiMethod + " api call invalid response =", apiResponseData);
          return Promise.reject("InvalidResponse");
        }
        let apiResponse: APIResponse = new APIResponse(apiResponseData);
        let t = new Date();
        t.setSeconds(t.getSeconds() + cacheTTL);
        result = {
          data: apiResponse.getResponse(),
          expire_time: t
        };
        if (storeInCache) {
          log.debug("cacheCall: saving to storage cacheTTL=" + cacheTTL + " data= ", result);
          await this.appConfig.appSettings.storage.storageSet("api-calls", key, result, this.storageType);
        }
      }
      this.memoryCache[key] = result.data;
    } else {
      log.debug("cacheCall: load from memory cache");
    }

    return new APIResponse(this.memoryCache[key]);
  }

  /**
   * Zwraca klucz wywołania API uźywany np. do cachownia danych
   *
   * @param apiClass Klasa API
   * @param apiMethod Metoda klasy API
   * @param apiParams Dodatkowe parametry
   */
  async getCacheKey(apiClass: string, apiMethod: string, apiParams: any = {}): Promise<string> {
    let params = "";
    for (let x = 0; x < apiParams.length; x++) params += "_" + apiParams[x];
    let key: string = "app_" + (await this.appConfig.getAppId()) + apiClass + "." + apiMethod + "." + params;
    //key = btoa(key);

    return key;
  }

  /**
   * Wykonuje wywołanie API
   *
   * @param  {string} apiClass Klasa API
   * @param  {string} apiMethod Metoda klasy API
   * @param  {any} apiParams={} Dodatkowe parametry
   * @param  {string} token Token autoryzacyjny
   * @param  {any} addHeaders Dodatkowe nagłówki
   * @param  {boolean=true} ignoreLoadingBar Czy pokazać pasek postępu dla wywołania
   * @returns Observable
   */
  public call(apiClass: string, apiMethod: string, apiParams: any = {}, token: string, addHeaders = [], ignoreLoadingBar = true): Observable<APIResponseData> {
    log.debug("call: apiClass=" + apiClass + ", apiMethod=" + apiMethod + ", token=" + token);
    log.warn("call: Headers ", addHeaders);
    let url = this.appConfig.Config.API.URL + "resource/" + apiClass + "/" + apiMethod;
    let body = {
      params: apiParams
    };
    let headers = this.getDefaultHeaders(token);
    
    if (addHeaders.length){
      for (let x=0; x<addHeaders.length; x++){
        headers = headers.append(addHeaders[x].name, addHeaders[x].value);
      }
      log.debug('call: addHeaders = ', headers);
    }
    if (ignoreLoadingBar)
      headers = headers.append("ignoreLoadingBar", "true");
    log.debug('call: ignoreLB=' + ignoreLoadingBar);

    log.warn("call: [HTTP_REQUEST] url=" + url + ", params=", apiParams);
    return this.getData(url, body, headers).pipe(shareReplay(1));
  }

  /**
   * Wykonuje zapytanie do API (POST)
   *
   * @param  {string} url Adres strony
   * @param  {any} body Zawartość posta
   * @param  {HttpHeaders} httpHeaders Nagłówki http
   * @param  {number=5} retries Liczba prób w przypdku błedu
   * @param  {number=3000} delayTime Przerwa w ms między próbami
   * @returns Observable
   */
  public getData(
    url: string,
    body: any,
    httpHeaders: HttpHeaders,
    retries: number = 5,
    delayTime: number = 3000
  ): Observable<any> {
    log.debug("getData url=" + url);
    const retryPipeline = retryWhen(errors =>
      errors.pipe(concatMap((e, i) => iif(() => i > retries, throwError(e), of(e).pipe(delay(delayTime)))))
    );

    return this.http.post(url, body, { headers: httpHeaders }).pipe(retryPipeline);
  }
}
