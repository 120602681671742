import { Component, OnInit, ViewChild } from '@angular/core';
import { PhotoData } from 'src/app/core/api/interfaces/photo-data';
import { Logger } from '../../core/logger.service';
import { ModalController, IonSlides } from '@ionic/angular';
const log = new Logger('GalleryDetailComponent');
@Component({
  selector: 'app-gallery-viewer',
  templateUrl: './gallery-viewer.component.html',
  styleUrls: ['./gallery-viewer.component.scss']
})
export class GalleryViewerComponent implements OnInit {

  @ViewChild(IonSlides) slides: IonSlides;
  photos: Array<PhotoData>;
  index: number = 0;
  options = {
    loop: true,
    initialSlide: this.index
  }
  constructor(
    private viewCtrl: ModalController    
  ) { }

  ngOnInit() {
    log.debug('init: index=' + this.index + ', photos=', this.photos);
    this.options.initialSlide = this.index;    
  }

  closeViewer(){
    log.debug('dismiss: ');
    this.viewCtrl.dismiss();
  }

 

}
