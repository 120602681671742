export let DEFAULT_MENU =
{
    "pl": [
      {
        "name": "info",
        "class": "hero",
        "enabled": true,
        "infokiosk": true,
        "menu": true
      },
      {
        "name": "login",
        "class": "left tall",
        "enabled": true,
        "infokiosk": false,
        "menu": true
      },
      {
        "name": "account",
        "class": "left tall",
        "enabled": true,
        "infokiosk": false,
        "menu": true
      },
      {
        "name": "schedule",
        "class": "right",
        "enabled": true,
        "infokiosk": true,
        "menu": true
      },
      {
        "name": "speakers",
        "class": "right",
        "enabled": true,
        "infokiosk": true,
        "menu": true
      },
      {
        "name": "map",
        "class": "right tall",
        "enabled": true,
        "infokiosk": false,
        "menu": true
      },
      {
        "name": "partners",
        "class": "left",
        "enabled": true,
        "infokiosk": true,
        "menu": true
      },
      {
        "name": "settings",
        "class": "left",
        "enabled": true,
        "infokiosk": false,
        "menu": true
      },
      {
        "name": "logout",
        "class": "wide",
        "enabled": true,
        "infokiosk": false,
        "menu": true
      },
      {
        "name": "language",
        "class": "wide",
        "enabled": true,
        "infokiosk": true,
        "menu": true
      }
    ],
    "en": [
      {
        "name": "info",
        "class": "hero",
        "enabled": true,
        "infokiosk": true,
        "menu": true
      },
      {
        "name": "login",
        "class": "left tall",
        "enabled": true,
        "infokiosk": false,
        "menu": true
      },
      {
        "name": "account",
        "class": "left tall",
        "enabled": true,
        "infokiosk": false,
        "menu": true
      },
      {
        "name": "schedule",
        "class": "right",
        "enabled": true,
        "infokiosk": true,
        "menu": true
      },
      {
        "name": "speakers",
        "class": "right",
        "enabled": true,
        "infokiosk": true,
        "menu": true
      },
      {
        "name": "map",
        "class": "right tall",
        "enabled": true,
        "infokiosk": true,
        "menu": true
      },
      {
        "name": "partners",
        "class": "left",
        "enabled": true,
        "infokiosk": true,
        "menu": true
      },
      {
        "name": "settings",
        "class": "left",
        "enabled": true,
        "infokiosk": false,
        "menu": true
      },
      {
        "name": "language",
        "class": "wide",
        "enabled": true,
        "infokiosk": false,
        "menu": true
      },
      {
        "name": "logout",
        "class": "wide",
        "enabled": true,
        "infokiosk": false,
        "menu": true
      }
    ]
  }