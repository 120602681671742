import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import {
  MenuController,
  NavController,
  ToastController,
  IonRouterOutlet,
  ModalController,
  ActionSheetController,
  PopoverController
} from "@ionic/angular";
import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Router, RouterEvent, NavigationEnd, RouterOutlet } from "@angular/router";
import { AppConfigService } from "./core/app-config.service";
import { ApiService } from "./core/api/api.service";
import { Logger } from "./core/logger.service";
import { MenuItemData } from "./core/interfaces/menu-item-data";
import { OneSignal } from "@ionic-native/onesignal/ngx";
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { UserProfileData } from "./core/api/interfaces/user-profile-data";
import { environment } from "./../environments/environment";
import { RouterStateService } from "./core/router-state.service";

const log = new Logger("app.component");
@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"]
})
export class AppComponent implements OnInit {
  pages: Array<MenuItemData> = [];
  infoKioskMode = false;
  isLogged: boolean;
  userData: UserProfileData;
  submenuShow = "";
  tabBarVisible: boolean = true;
  // set up hardware back button event.
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;
  backButtonClickedUrl = '';

  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  private isInitialized: boolean = false;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private menuCtrl: MenuController,
    private navCtrl: NavController,
    private appConfig: AppConfigService,
    private api: ApiService,
    private screenOrientation: ScreenOrientation,
    private oneSignal: OneSignal,
    private routerState: RouterStateService,
    private menu: MenuController,
    private toast: ToastController,
    public modalCtrl: ModalController,
    private actionSheetCtrl: ActionSheetController,
    private popoverCtrl: PopoverController
  ) {
    log.debug("constructor");
    this.routerState.loadRouting();
  }

  async ngOnInit() {
    log.debug("ngOnInit()");
    if (environment.production) {
      Logger.enableProductionMode();
    }
    this.subscribeEvents();
    this.initializeApp();
    this.buildMenu();
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.pages.map(p => {
          return (p["active"] = event.url === p.url);
        });
        //wyłączamy menu dla logowania
        /*  if (event.url === '/login')
            this.menuCtrl.enable(false);
            */
      }
    });
  }
  async ionViewWillEnter() {
    log.debug("ionViewWillEnter");
  }

  ionDidClose() {
    log.debug("menu closed");
  }

  /**
   * Inicjalizacja aplikacji
   *
   * @returns Promise
   */
  async initializeApp(): Promise<boolean> {
    log.debug("initializeAPP: start", this.isInitialized);
    if (this.isInitialized) return this.isInitialized;
    log.debug("initializeAPP: run");

    log.debug("initialzeApp: waiting for AppConfig.ready");
    await this.appConfig.ready();
    this.infoKioskMode = this.appConfig.isInfoKioskMode();

    log.debug("initializeApp: waiting for api ready / autologin");
    await this.api.ready();

    log.debug("initializeApp: subscribeEvents");
    this.subscribeEvents();

    this.oneSignalInit();
    if (await this.appConfig.isCordova()) {
      log.debug("initializeApp: hiding splash screen");
      if (this.platform.is("ios")) {
        this.statusBar.overlaysWebView(true);
        this.statusBar.backgroundColorByHexString("#ffffff");
      }
      this.splashScreen.hide();

      if (this.screenOrientation) this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
    }
    this.isInitialized = true;
    log.info("initializeAPP: end", this.isInitialized);
    this.appConfig.appEvents.publish(this.appConfig.appEvents.event.APP_READY);
    this.backButton();
    return this.isInitialized;
  }

  async logout() {
    await this.menuCtrl.close();
    await this.api.logout();
    this.appConfig.appEvents.publish(this.appConfig.appEvents.event.NAVIGATION_SET_PAGE, "/login");
  }

  private backButton() {
    this.platform.backButton.subscribe(async () => {      
      this.backButtonClickedUrl = this.router.url;
      log.info("backButtonClicked: ", this.backButtonClickedUrl);
      try {
        const element = await this.actionSheetCtrl.getTop();
        if (element) {
          log.debug("backButtonClicked: actionSheetClose");
          element.dismiss();
          return;
        }
      } catch (error) {
        log.error(error);
      }

      // close popover
      try {
        const element = await this.popoverCtrl.getTop();
        if (element) {
          log.debug("backButtonClicked: popoverClose");
          element.dismiss();
          return;
        }
      } catch (error) {
        log.error(error);
      }

      // close modal
      try {
        const element = await this.modalCtrl.getTop();
        if (element) {
          log.debug("backButtonClicked: modalClose");
          element.dismiss();
          return;
        }
      } catch (error) {
        log.error(error);
      }

      // close side menua
      try {
        const element = await this.menu.isOpen()
        if (element === true) {          
          log.debug("backButtonClicked: menuClose");
          this.menu.close();
          return;
        }
      } catch (error) {
        log.error(error);
      }

      if (this.router.url !== this.backButtonClickedUrl)
        return;

      if (
        this.router.url === "/refresh" ||
        this.router.url === "/settings" ||
        this.router.url.indexOf("/social") == 0 ||
        this.router.url === "/news/news" ||
        this.router.url === "/news/movies" ||
        this.router.url === "/news/galleries" ||
        this.router.url === "/partners" ||
        this.router.url === "/speakers/speakers" ||
        this.router.url === "/schedule" ||
        this.router.url === "/chat/chat" || 
        this.router.url.indexOf("/static") == 0 ||
        this.router.url.indexOf("/home/static") == 0 ||
        this.router.url.indexOf("/error") == 0
      ){
        this.navCtrl.navigateRoot("/home");
        return;
      }
     
      log.info("backButtonClicked2: ", this.router.url);
      this.routerOutlets.forEach(async (outlet: IonRouterOutlet) => {
        if (outlet && outlet.canGoBack()) {
          outlet.pop();          
        }
        else if (this.router.url === "/home/home") {
          console.warn("backButton: Home");
          if (new Date().getTime() - this.lastTimeBackPress < this.timePeriodToExit) {
            // this.platform.exitApp(); // Exit from app
            navigator["app"].exitApp(); // work in ionic 4
          } else {
            const toast = await this.toast.create({
              message: this.appConfig.translator.instant("alerts.closeConfirm"),
              duration: 2000,
              position: "middle"
            });
            toast.present();
            this.lastTimeBackPress = new Date().getTime();
          }
        }
      });

 
    });
  }

  private subscribeEvents() {
    this.subscribeMenuEvents();
    this.appConfig.appEvents.subscribe(this.appConfig.appEvents.event.USER_CHANGE_LOGIN_STATUS, isLogged => {
      log.debug("USER_CHANGE_LOGIN_STATUS", isLogged);
      this.isLogged = isLogged;
      this.appConfig.appEvents.publish(this.appConfig.appEvents.event.NEED_REBUILD_MENU);
      if (isLogged) {
        this.router.events.subscribe((event: RouterEvent) => {
          if (event instanceof NavigationEnd) {
            if (event.url === "/login") this.navCtrl.navigateRoot("/home");
          }
        });
      }
    });

    this.appConfig.appEvents.subscribe(this.appConfig.appEvents.event.USER_CHANGE_PROFILE, () => {
      this.appConfig.appEvents.publish(this.appConfig.appEvents.event.NEED_REBUILD_MENU);
    });

    this.appConfig.appEvents.subscribe(this.appConfig.appEvents.event.APPLICATION_EVENT_CHANGED, () => {
      this.appConfig.appEvents.publish(this.appConfig.appEvents.event.NEED_REBUILD_MENU);
    });

    this.appConfig.appEvents.subscribe(this.appConfig.appEvents.event.CHANGE_LANGUAGE, lang => {
      log.debug("appComponent events:BuildMenu because of user change language to " + lang);
      this.appConfig.appEvents.publish(this.appConfig.appEvents.event.NEED_REBUILD_MENU);
      this.navCtrl.navigateRoot("/home");
    });

    this.appConfig.appEvents.subscribe(this.appConfig.appEvents.event.NAVIGATION_SET_PAGE, page => {
      log.info("appComponent events:Navigationg to ", page);
      this.navCtrl.navigateRoot(page);
    });

    this.appConfig.appEvents.subscribe(this.appConfig.appEvents.event.APPLICATION_EVENT_CHANGED, newId => {
      log.info("appComponent events: User change Event to ", newId);
      this.api.getConferenceConfig(true).then(() => {
        this.appConfig.appEvents.publish(this.appConfig.appEvents.event.NEED_REBUILD_MENU);
        this.navCtrl.navigateRoot("/home");
      });
    });
  }

  private oneSignalInit() {
    if (!this.appConfig.Config.OneSignal.enabled) {
      log.debug("oneSignalInit: pushes are disabled in config");
      return false;
    }

    this.platform.ready().then(() => {
      if (this.platform.is("cordova"))
        if (this.oneSignal) {
          log.info("OneSignal init ...");
          // oneSignal.setLogLevel( {
          // logLevel: 5,
          // visualLevel: 5
          // });
          log.info(
            "OneSingalInit: AppID:" +
              this.appConfig.Config.OneSignal.appID +
              ", GPN:" +
              this.appConfig.Config.OneSignal.android
          );

          this.oneSignal.startInit(this.appConfig.Config.OneSignal.appID, this.appConfig.Config.OneSignal.android);
          this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);

          // oneSignal.enableInAppAlertNotification( true );
          // oneSignal.enableNotificationsWhenActive(true);

          this.oneSignal.handleNotificationReceived().subscribe(data => {
            log.info("OneSingalInit: Notification: " + JSON.stringify(data.payload, null, 4));
            if (data.payload.additionalData.type == "chat")
              this.appConfig.appEvents.publish(
                this.appConfig.appEvents.event.PUSH_MESSAGE_RECEIVED,
                data.payload.additionalData
              );
          });

          this.oneSignal.handleNotificationOpened().subscribe(() => {
            // do something when a notification is opened
          });

          this.oneSignal.endInit();
          log.debug("OneSingalInit: end init");
          // oneSignal.setSubscription(true);
        }
    });
  }

  private async subscribeMenuEvents() {
    this.appConfig.appEvents.subscribe(this.appConfig.appEvents.event.NEED_REBUILD_MENU, () => {
      this.buildMenu(true).then(() => {
        log.debug("subscribeEvents events: Menu rebuilded");
      });
    });
  }

  showSubmenu(submenu) {
    if (this.submenuShow == submenu) this.submenuShow = undefined;
    else this.submenuShow = submenu;
  }

  getDetailIcon(submenu) {
    if (this.submenuShow == submenu) return "arrow-dropup";
    else return "arrow-dropdown";
  }
  /**
   * Buduje menu
   */
  async buildMenu(forceReload: boolean = false) {
    log.debug("buildMenu: start=" + this.isLogged);

    try {
      log.debug("buildMenu: getMenu");
      this.pages = await this.api.getMenu(forceReload);
      this.isLogged = this.api.isUserLogged();
      log.debug("buildMenu: isLogged=" + this.isLogged);
      if (this.isLogged) this.userData = await this.api.getUserDetail();
      else this.userData = undefined;
    } catch (error) {
      log.error("buildMenu: error", error);
    } finally {
      log.debug("buildMenu: end", this.pages);
    }
  }

  trackByFn(index: number, item: any): string {
    return item.url;
  }
}
