import { Injectable } from '@angular/core';
import { Token } from './token';
import { Logger } from '../logger.service';
import { ClientAuthService } from './client-auth.service';
import { AppConfigService } from '../app-config.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { TokenResponseData } from './interfaces/token-response-data';
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { share } from 'rxjs/operators';
import { UserCredentialsData } from './interfaces/user-credentials-data';
import { FacebookEmailResponseData } from './interfaces/facebook-email-response-data';
import { Facebook, FacebookLoginResponse } from '@ionic-native/facebook/ngx';
import { LinkedIn, LinkedInLoginScopes } from '@ionic-native/linkedin/ngx';
import { RequestOptions } from '@angular/http';
import { APIResponse } from './api-response';
import { APIResponseData } from './interfaces/api-response-data';
import { ProviderWithInit } from './provider-with-init';
const log = new Logger('UserAuthService');

@Injectable({
  providedIn: 'root'
})
export class UserAuthService extends ProviderWithInit {

  private HTTP_TIMEOUT = 7000;
  private token: Token;
  private isUserLoggedSubject = new BehaviorSubject<boolean>(false);
  private isReady: boolean = false;

  private LOGIN_TYPE_STANDARD = 'standard';
  private LOGIN_TYPE_FACEBOOK = 'facebook';
  private LOGIN_TYPE_LINKEDIN = 'linkedin';

  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
    private clientAuth: ClientAuthService,
    private facebook: Facebook,
    private linkedin: LinkedIn,
  ) {
    super();
    log.debug('constructor');
    this.token = undefined;      
    this.ready().then(
      () => {
        
      }
    ); 
  }

  protected async init(){
    log.debug('ready: start');
    log.info('ready: true');
    super.init();    
  }

  /**
   * Wewnętrzna funkcja logująca uzytkownika
   * 
   * @param  {string} username
   * @param  {string} password
   * @param {string} [loginType='standard'] informacja czy logowanie tokenem
   * @returns Promise
   */
  async login(username: string, password: string, loginType: string = this.LOGIN_TYPE_STANDARD): Promise<Token> {
    log.debug('login() start, username=', username);

    if (!this.isUserLogged())
      this.appConfig.appEvents.publish(this.appConfig.appEvents.event.USER_CHANGE_LOGIN_STATUS, false);
    this.token = undefined;
    this.isUserLoggedSubject.next(false);
    let token = await this.getTokenFromServer(username, password);

    if (username.indexOf("@") < 0)
      loginType = "multi";
    log.debug('login: token from server', token)
    if (token != undefined) {
      this.isUserLoggedSubject.next(true);
      this.appConfig.appEvents.publish(this.appConfig.appEvents.event.USER_LOGIN);
      this.appConfig.appEvents.publish(this.appConfig.appEvents.event.USER_CHANGE_LOGIN_STATUS, true);
      this.saveTokenToStorage(token);
      this.token = token;
      let settings = await this.appConfig.appSettings.getSettings();
      if (settings.storeLoginCredentials && loginType) {
        let credentialsData: UserCredentialsData = {
          login: username,
          password: password,
          type: loginType
        };
        this.saveUserCredentialsInStorage(credentialsData);
      }
      settings.loginType = loginType;
      return token;
    }
    return token;
  }

  /**
   * Odczyt tokena z serwera (autoryzacja)
   * @returns Promise<Token>
   */
  private async getTokenFromServer(username: string, password: string): Promise<Token> {
    let body = {
      username: username,
      password: password,
      grant_type: 'password',
      client_id: this.appConfig.Config.API.ClientId
    };
    log.debug('getTokenFromServer: sending request', body);

    let url = this.appConfig.Config.API.URL + 'token';
    let headers = { headers: this.clientAuth.getDefaultHeaders() };

    let response = await this.http.post<TokenResponseData>(url, body, headers).pipe(
      timeout(this.HTTP_TIMEOUT)
    ).toPromise();
    let token = new Token(response, true);
    if (token.isValid) {
      log.debug('getTokenFromServer: server returns valid token', token);
      return token;
    }
    log.debug('getTokenFromServer: error requesting token from server', response);
    return undefined;

  }

  /**
   * Zwraca token lub undefined gdy brak / niewazny
   * @returns Token
   */
  async getToken(): Promise<Token> {
    if (!this.token || !this.token.isValid()) {
      this.token = undefined;
      this.isUserLoggedSubject.next(false);
      await this.autoLogin();
    }
    return this.token;
  }

  /**
   * Czy aplikacja jest zalogowana
   * @returns Observable<boolean>
   */
  isLoggedIn(): Observable<boolean> {
    return this.isUserLoggedSubject.asObservable().pipe(share());
  }

  isUserLogged(): boolean {
    if (this.token)
      return this.token.isValid();
    return false;
  }

  /**
    * Automatycznie loguje uzytkownika, gdy znajdzie w Storageu token odświezania
    * 
    * @returns {Promise<any>} 
    * 
    */
  async autoLogin(): Promise<any> {
    if (this.isUserLogged())
      return;
    log.debug("autologin start");
    if (this.appConfig.isInfoKioskMode()) return false;
    let token = await this.getTokenFromStorage(true);
    if (!token) return false;
    if (!token.isValid())
      token = await this.refreshToken(token.getToken().refresh_token);
    if (!token) {
      await this.removeUserToken();
      return false;
    }
    if (token.isValid()) {
      this.token = token;
      log.info('autologin User logged:', token);
      this.isUserLoggedSubject.next(true);
      this.appConfig.appEvents.publish(this.appConfig.appEvents.event.USER_CHANGE_LOGIN_STATUS, true);
      return true;
    }
  }
  /**
   * Odświeza token uźytkownika
   * @param  {string} refresh_token
   * @returns Promise
   */
  private async refreshToken(refresh_token: string): Promise<Token> {
    if (!refresh_token) {
      log.debug('refreshToken Invalid refresh token, refresh_token');
      return undefined;
    }

    let body = {
      refresh_token: refresh_token,
      grant_type: 'refresh_token',
      client_id: this.appConfig.Config.API.ClientId
      //client_secret: this.appConfig.Config.API.ClientSecret
    };
    log.debug('refreshToken: sending request', body);
    let url = this.appConfig.Config.API.URL + 'token';
    let headers = { headers: this.clientAuth.getDefaultHeaders() };

    let response: TokenResponseData;
    let token: Token;
    try {
      response = await this.http.post<TokenResponseData>(url, body, headers).pipe(
        timeout(this.HTTP_TIMEOUT)
      ).toPromise();
      token = new Token(response, true);
    } catch (error) {
      log.error('refreshToken: error requesting token from server', error);
      return undefined;
    }
    if (token.isValid) {
      log.debug('refreshToken: server returns valid token', token);
      return token;
    }
    log.warn('refreshToken: error requesting token from server: got invalid token', response);
    return undefined;
  }

  /**
   * Odczyt tokenu z magazynu
   * @returns Promise<Token>
   */
  private async getTokenFromStorage(returnExpired: boolean = true): Promise<Token> {
    let data = await this.appConfig.appSettings.storage.storageGet('tokens', 'user');
    let token: Token;
    if (data != undefined) {
      log.debug('getTokenFromStorage: token loaded', data);
      token = new Token(data);
      if (token.isValid())
        return token;
      else {
        if (returnExpired && token.canRefresh()) {
          log.debug('getTokenFromStorage: token expired but can refresh');
          return token;
        }

        log.debug('getTokenFromStorage: token loaded but is invalid');
        return undefined;
      }
    }
    log.debug('getTokenFromStorage: no token in storage');
    return undefined;
  }

  /**
   * Zapis tokenu do magazynu
   * @param token Dane do zapisania
   * @returns Promise
   */
  private saveTokenToStorage(token: Token): Promise<any> {
    return this.appConfig.appSettings.storage.storageSet('tokens', 'user', token.getToken());
  }

  /**
   * Usuwa token uzytkownika ze storage
   * @returns Promise
   */
  async removeUserToken(): Promise<any> {
    log.debug('removeUserToken:');
    return await this.appConfig.appSettings.storage.storageSet('tokens', 'user', []);
  }

  /**
   * Czyści zapisane dane logowania uźytkownika
   * @returns Promise
   */
  async cleanUserCredentials(): Promise<any> {
    log.debug('cleanUserCredential');
    return await this.appConfig.appSettings.storage.storageSet('userData', 'credentials', []);
  }

  /**
   * Odczyt danych logowania ze storage
   * @returns Promise
   */
  async getUserCredentialsFromStorage(): Promise<UserCredentialsData> {
    log.debug('getUserCredentialsFromStorage: start');
    let data = await this.appConfig.appSettings.storage.storageGet('userData', 'credentials');    
    if (data)
    {
        data = <UserCredentialsData>(data);
    }
    else data = undefined;
    log.debug('getUserCredentialsFromStorage: data ' + ((data===undefined) ? 'not found' : 'found'));
    return data;
  }
  /**
   * Zapis danych logowania do storage
   * @param  {UserCredentialsData} data
   * @returns Promise
   */
  async saveUserCredentialsInStorage(data: UserCredentialsData): Promise<any> {
    log.error('saveUserCredentialsInStorage');
    return this.appConfig.appSettings.storage.storageSet('userData', 'credentials', data);
  }

  /**
   * Wyloguj uzytkownika
   * 
   * @param  {boolean=false} automatic czy automatyczne
   * @returns Promise
   */
  async logout(automatic: boolean = false): Promise<any> {
    this.token = undefined;
    //w przypadku automatycznego wylogowania - np wskutek utraty połączenia z internetem
    //nie kasujemy tokenów ze storage'u
    this.isUserLoggedSubject.next(false);
    if (!automatic) {
      await this.removeUserToken();
      // await this.cleanUserCredentials();
      this.appConfig.appEvents.publish(this.appConfig.appEvents.event.USER_LOGOUT);
      log.warn('logout by user');
    } else {
      log.warn('logout - automatic');
    }
    
    this.appConfig.appEvents.publish(this.appConfig.appEvents.event.USER_CHANGE_LOGIN_STATUS, false);
    return true;
  }

  /**
    * Loguje uzytkownika przy pomocy API Facebooka
    * 
    * @returns {Promise<any>} 
    * 
    * @memberOf UserData
    */
  async loginWithFacebook(): Promise<any> {
    let requestPath = '/me?fields=email,name,first_name,last_name,gender,verified,link,locale,updated_time';
    let permissions = ['public_profile', 'email'];
    log.debug('loginWithFacebook: APP_ID');
    let res: FacebookLoginResponse;
    try {
      res = await this.facebook.login(permissions);
    } catch (error) {
      log.error('loginWithFacebook: overall error:', error)
      return { error: 'facebook.overallError' };
    }

    if (res.status == 'connected') {
      log.info('loginWithFacebook: logged', res);
      let fbAccessToken = res.authResponse.accessToken;
      let response: FacebookEmailResponseData = await this.facebook.api(requestPath, permissions);
      log.info('loginWithFacebook: userData', response);
      if (!response.email) {
        return { error: 'facebook.userDataNotPermitted' }
      }
      let login = response.email;
      let params = {
        email: response.email,
        id: response.id,
        token: fbAccessToken,
        client_id: this.appConfig.Config.API.ClientId
      };
      let apiResponse: APIResponse;
      try {
        apiResponse = new APIResponse(await this.http.get<APIResponseData>(this.appConfig.Config.API.URLFB, { params: params }).toPromise());
      } catch (error) {

      }

      log.debug('loginWithFacebook:  API response:', apiResponse);
      if (apiResponse.isSuccess()) {
        let data = apiResponse.getContent();
        let loginSuccess;
        try {
          loginSuccess = await this.login(login, data.token, this.LOGIN_TYPE_FACEBOOK);
        } catch (error) {
          log.error("loginWithFacebook: login failed - FB logged but API denied");
          return { error: 'facebook.facebookSuccessAPIDenied' };
        }

        if (loginSuccess) {
          log.error("loginWithFacebook: login success");
          return { success: loginSuccess };
        }
        else {
          log.error("loginWithFacebook: login failed - FB logged but API denied");
          return { error: 'facebook.facebookSuccessAPIDenied' };
        }
      }
    } else {
      log.error('loginWithFacebook: user not logged');
      return { error: 'facebook.noLocalAccount' };
    }

  }





  /**
   * Loguje uzytkownika przy pomocy API LinkendIna
   * 
   * UWAGA! Na chwilę obecną 20170422 funkcja nie działa
   * API z LinkedIna nie zwraca identyfikatora uzytkownika
   * 
   * @returns {Promise<any>} 
   * 
   * @memberOf UserData
   */
  async loginWithLinkedIn(): Promise<any> {

    log.debug('loginWithLinkedIn: start');
    const scopes = [<LinkedInLoginScopes>'r_emailaddress', <LinkedInLoginScopes>'r_basicprofile'];
    try {
      await this.linkedin.login(scopes, true);
    } catch (error) {
      log.error('loginWithLinkedIn: error', error)
      return { error: 'linkedin.userNotLogged' };
    }

    let session = await this.linkedin.getActiveSession();

    if (!session) {
      log.error('loginWithLinkedIn: No active session');
      return { error: 'linkedin.userDataNotPermitted' };
    }
    log.debug('loginWithLinkedIn: session', session);

    let response;
    try {
      response = await this.linkedin.getRequest('people/~:(id,email-address)');
    } catch (error) {
      log.error('loginWithLinkedIn: error', error)
      return { error: 'linkedin.userDataNotPermitted' };
    }
    log.debug('loginWithLinkedIn: Pobrano dane:', response);
    let login = response.emailAddress;
    let linkedInAccessToken = session.accessToken;
    let params = {
      email: response.emailAddress,
      id: response.id,
      token: linkedInAccessToken,
      client_id: this.appConfig.Config.API.ClientId
    };
    log.debug('loginWithLinkedIn: params', params);
    let apiResponse: APIResponse = new APIResponse(await this.http.get<APIResponseData>(this.appConfig.Config.API.URLLinkedIn, { params: params }).toPromise());
    log.debug('loginWithFacebook:  API response:', apiResponse);
    if (apiResponse.isSuccess()) {
      let data = apiResponse.getContent();
      let loginSuccess;
      try {
        loginSuccess = await this.login(login, data.token, this.LOGIN_TYPE_LINKEDIN);
      } catch (error) {
        log.error("loginWithLinkedIn: login failed - LinkedIn logged but API denied");
        return { error: 'linkedin.linkedinSuccessAPIDenied' };
      }

      if (loginSuccess) {
        log.error("loginWithLinkedIn: login success");
        return { success: loginSuccess };
      }
      else {
        log.error("loginWithLinkedIn: login failed - LinkedIn logged but API denied");
        return { error: 'linkedin.linkedinSuccessAPIDenied' };
      }
    } else {
      log.error("loginWithLinkedIn: login failed - LinkedIn logged but API failed to generate token");
      return { error: 'linkedin.noLocalAccount' };
    }
  }

}
