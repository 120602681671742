import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ImageViewerComponent} from './image-viewer.component';
import { MomentModule } from 'ngx-moment';
@NgModule({
     declarations: [
       ImageViewerComponent
     ],
     imports: [
       IonicModule,
       CommonModule,
       FormsModule,
       MomentModule,
       TranslateModule.forChild()
     ],
     exports:[ImageViewerComponent],
     entryComponents: [
       ImageViewerComponent
     ]
})
export class ImageViewerComponentModule {}