import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { File } from '@ionic-native/file/ngx';
import { MomentModule } from 'ngx-moment';
import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SecureStorage } from '@ionic-native/secure-storage/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoaderService } from './core/loader.service';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { SharedModule } from './shared/shared.module';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Network } from '@ionic-native/network/ngx';
import { MomentPipe } from './core/moment.pipe';
import { Camera } from '@ionic-native/camera/ngx';
import { Facebook} from '@ionic-native/facebook/ngx';
import { LinkedIn} from '@ionic-native/linkedin/ngx';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GalleryViewerComponentModule } from './components/gallery-viewer/gallery-viewer.module';
import { StreamingMedia } from '@ionic-native/streaming-media/ngx';
import { YoutubeVideoPlayer } from '@ionic-native/youtube-video-player/ngx';
import { ImageViewerComponentModule } from './components/image-viewer/image-viewer.module';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { NativePageTransitions } from '@ionic-native/native-page-transitions/ngx';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/translations/', '.json');
}

@NgModule({
  declarations: [AppComponent, MomentPipe],  
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot({
      swipeBackEnabled: true ,
      mode: 'ios'
    }
    ), 
    AppRoutingModule,
    HttpClientModule,
    MomentModule,
    SharedModule.forRoot(),    
    IonicStorageModule.forRoot(),
    GalleryViewerComponentModule,
    ImageViewerComponentModule,
    LoadingBarHttpClientModule,
    LoadingBarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
],  
  providers: [
    LoaderService,
    File,
    StatusBar,
    SplashScreen,
    SecureStorage,
    NativeStorage,
    ScreenOrientation,
    OneSignal,   
    Keyboard, 
    Camera,
    Facebook,
    LinkedIn,
    InAppBrowser,
    Network,
    StreamingMedia,
    NativeAudio,
    YoutubeVideoPlayer,
    NativePageTransitions,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
