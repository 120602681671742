import { Injectable } from "@angular/core";
import { ClientAuthService } from "./client-auth.service";
import { UserAuthService } from "./user-auth.service";
import { ApiCallService } from "./api-call.service";
import { AppConfigService } from "../app-config.service";
import { APIResponse } from "./api-response";
import { Logger } from "../logger.service";
import { Token } from "./token";
import { ConferenceConfigData } from "./interfaces/conference-config-data";
import { ArticleData } from "./interfaces/article-data";
import { ArticleHtmlData } from "../interfaces/article-html-data";
import { DomSanitizer } from "@angular/platform-browser";
import { PartnersData } from "./interfaces/partners-data";
import { SessionsData } from "./interfaces/sessions-data";
import { UserProfileData } from "./interfaces/user-profile-data";
import { UserEventData } from "./interfaces/user-event-data";
import { MenuItemData } from "../interfaces/menu-item-data";
import { SessionData } from "./interfaces/session-data";
import { PlacesData } from "./interfaces/places-data";
import { TimelineData, TimeLineSessionData } from "../interfaces/timeline-data";
import { SessionWithSpeakersData } from "../interfaces/session-with-speakers-data";
import { SpeakerData } from "./interfaces/speaker-data";
import { SpeakerWithSessionsData } from "../interfaces/speaker-with-sessions-data";
import { SpeakerSearchResultData } from "./interfaces/speakers-search-result-data";
import { UserCredentialsData } from "./interfaces/user-credentials-data";
import { AlertController, ToastController, NavController } from "@ionic/angular";
import { ProviderWithInit } from "./provider-with-init";
import { AppsListData } from "./interfaces/apps-list-data";
import { DEFAULT_MENU } from "../defaults/default-menu-data";
import { SegmentTabsData } from "../interfaces/segment-tabs-data";
import { ChatUsersData } from "./interfaces/chat-users-data";
import { ChatUsersSearchResultData } from "./interfaces/chat-users-search-result-data";
import { ChatUserData } from "./interfaces/chat-user-data";
import { StringUtils } from "../string-utils";
import { ChatMessagesData, UserMessagesData } from "./interfaces/chat-messages-data";
import { PersonData, PeopleListData } from "../interfaces/people-list-data";
import { ChatThreadMessagesData } from "./interfaces/chat-thread-messages-data";
import { AudioService } from "../audio.service";
import { DEFAULT_MODULES } from "../defaults/modules";
import { ChatMessageData } from "./interfaces/chat-message-data";
import { empty } from "rxjs";

const log = new Logger("ApiService");
@Injectable({
  providedIn: "root"
})
export class ApiService extends ProviderWithInit {
  private appToken: Token;
  private userToken: Token;

  private sessionsData: SessionsData;
  private speakers: Array<SpeakerData>;
  private userDetail: UserProfileData;
  private menu: Array<MenuItemData>;
  private usersList: ChatUsersData;
  private chatMessages: PeopleListData;

  readonly ELEMENT_TYPE_NEWS = "news";
  readonly ELEMENT_TYPE_GALLERIES = "galleries";
  readonly ELEMENT_TYPE_MOVIES = "movies";
  readonly ELEMENT_TYPE_YOUTUBE = "youtube";
  readonly ELEMENT_TYPE_TWITTER = "twitter";
  readonly ELEMENT_TYPE_FACEBOOK = "facebook";
  readonly ELEMENT_TYPE_STATIC_PAGE = "static";

  constructor(
    private clientAuth: ClientAuthService,
    private userAuth: UserAuthService,
    private appConfig: AppConfigService,
    private apiCall: ApiCallService,
    private sanitizer: DomSanitizer,
    private alertController: AlertController,
    private toastController: ToastController,
    private navCtrl: NavController,
    private audio: AudioService
  ) {
    super();
    log.debug("constructor");
  }

  protected async init() {
    log.debug("ready start");
    await this.checkToken();
    await this.userAuth.ready();
    await this.userAuth.autoLogin();
    this.audio.ready();
    super.init();
    log.debug("ready true");
  }

  /**
   * Usuwa wszystkie zapisane dane uzytkownika
   *
   * @returns Promise
   */
  async cleanAllData(): Promise<any> {
    log.warn("cleanAllData");
    await this.ready();
    await this.userAuth.cleanUserCredentials();
    await this.appConfig.appSettings.clean();
    await this.userAuth.removeUserToken();
    await this.apiCall.clearCache();
    await this.appConfig.appSettings.storage.storageSet("app", "appId", undefined);
    this.sessionsData = undefined;
    this.speakers = undefined;
    this.userDetail = undefined;
    this.menu = undefined;
    this.chatMessages = undefined;
    this.usersList = undefined;
    return await this.getConferenceConfig();
  }

  /**
   * Sprawdza waźność tokena
   * @returns Promise
   */
  async checkToken(): Promise<boolean> {
    if (!this.appToken || !this.appToken.isValid()) {
      log.debug("checkToken: no token or invalid, trying to login");
      this.appToken = await this.clientAuth.login();
    }
    let res = this.appToken.isValid();
    if (!res) {
      //todo obsługa trybu offline
      log.error("checkToken: cannot obtain new token!!! Offlne or API Server error");
      this.goToErrorPage("api");
      return undefined;
    }

    return res;
  }
  /**
   * Sprawdza czy uzytkownika ma wazny token (jest zalogowany)
   * @returns Promise
   */
  async checkUserToken(): Promise<boolean> {
    await this.ready();
    let token: Token = await this.userAuth.getToken();
    if (!token) return false;
    return token.isValid();
  }

  /**
   * Pobiera dane konfiguracyjne konferencji
   *
   * @param  {boolean} forceRequest=false Czy wymusić źądanie z serwera
   * @returns Promise
   */
  async getConferenceConfig(forceRequest: boolean = false): Promise<ConferenceConfigData> {
    log.debug("getConferenceConfig: start forceRequest=" + forceRequest);
    let workspace = this.appConfig.Config.API.methods.Application.getConfig;
    let content: ConferenceConfigData;
    try {
      await this.checkToken();
      let data: APIResponse = await this.apiCall.cacheCall(
        workspace.class,
        workspace.method,
        [await this.appConfig.getAppId()],
        this.appToken.getAccessTokenString(),
        forceRequest
      );

      log.debug("getConferenceConfig:", data);
      content = data.getContent();
    } catch (error) {
      log.error("getConferenceConfig: error", error);
      this.goToErrorPage("api");
      return undefined;
    }
    return <ConferenceConfigData>content;
  }
  /**
   * Zwraca ID konferencji
   *
   * @param  {} forceRequest=false Czy wymusić źądanie z serwera
   * @returns Promise
   */
  async getConferenceId(forceRequest = false): Promise<number> {
    log.debug("getConferenceId forceRequest =", forceRequest);
    let data = await this.getConferenceConfig(forceRequest);
    log.debug("getConferenceId=", data.event_id);
    return data.event_id;
  }

  async getInfoTabs(currentType: string = ""): Promise<Array<SegmentTabsData>> {
    let data = await this.getConferenceConfig();
    let tabs: Array<SegmentTabsData> = [];
    let lang = await this.appConfig.getLang();
    if (!data.pages) return [];
    let menu = data.pages.menu.info[lang];
    let page = data.pages.pages.info[lang];
    let tab = "";
    let active = false;

    tabs.push({
      icon: "home",
      name: "Start",
      tab: this.getUrlPrefix("home") + "home",
      active: currentType == this.getUrlPrefix("home") + "home" ? true : false
    });

    for (let x = 0; x < menu.length; x++) {
      tab = this.getUrlPrefix("home") + "static/info/" + menu[x].name + "/0";
      active = tab == currentType ? true : false;
      tabs.push({
        tab: tab,
        name: page[menu[x].name][0].title_alt,
        icon: menu[x].icon,
        active: active
      });
    }
    menu = data.pages.menu.map[lang];
    page = data.pages.pages.map[lang];
    for (let x = 0; x < menu.length; x++) {
      tab = this.getUrlPrefix("home") + "static/map/" + menu[x].name + "/0";
      active = tab == currentType ? true : false;
      tabs.push({
        tab: tab,
        name: page[menu[x].name][0].title_alt,
        icon: menu[x].icon,
        active: active
      });
    }
    log.debug("getInfoTabs currentType=" + currentType, tabs);
    return tabs;
  }

  async getTabBarTabs(): Promise<Array<SegmentTabsData>> {
    let lang = await this.appConfig.getLang();
    let tabs: Array<SegmentTabsData> = [];
    if (this.appConfig.Config.Tabs[lang].length) {
      tabs = this.appConfig.Config.Tabs[lang];
      log.debug("getTabBarTabs: load from config file=", tabs);
      return tabs;
    }

    let maxTabs = 4;
    let component = "";
    let data = await this.getConferenceConfig();
    let isLogged = this.isUserLogged();
    let preffered = ["info", "schedule", "speakers", "news", "social", "partners"];

    for (let x = 0; x < preffered.length; x++) {
      for (let y = 0; y < data.modules[lang].length; y++) {
        if (maxTabs == 0) break;
        if (preffered[x] == "chat" && !isLogged) continue;
        if (preffered[x] == "info") component = "home";
        else component = preffered[x];

        if (preffered[x] == data.modules[lang][y].name) {
          maxTabs--;
          tabs.push({
            tab: component,
            name: preffered[x].charAt(0).toUpperCase() + preffered[x].slice(1),
            icon: DEFAULT_MODULES[preffered[x]].icon,
            active: false
          });
        }
      }
    }
    log.debug("getTabBarTabs: ", tabs);
    return tabs;
  }

  /**
   * Zwraca zakładki wiadomośi
   *
   * @returns {Promise<Array<SegmentTabsData>>}
   */
  async getNewsTabs(currentType: string = ""): Promise<Array<SegmentTabsData>> {
    let data = await this.getConferenceConfig();
    let tabs: Array<SegmentTabsData> = [];
    let lang = await this.appConfig.getLang();
    let modules = data.modules[lang];
    for (let x = 0; x < modules.length; x++) {
      if (modules[x].name == "news" || modules[x].name == "movies" || modules[x].name == "galleries")
        tabs.push({
          icon: "",
          name: modules[x].name.charAt(0).toUpperCase() + modules[x].name.slice(1),
          tab: this.getUrlPrefix("news") + modules[x].name,
          active: currentType == modules[x].name ? true : false
        });
    }

    log.debug("getNewsTabs: current=" + currentType + ", result=", tabs);
    return tabs;
  }

  /**
   * Zwraca zakładki chatau
   *
   * @returns {Promise<Array<SegmentTabsData>>}
   */
  async getChatTabs(currentType: string = ""): Promise<Array<SegmentTabsData>> {
    let tabs: Array<SegmentTabsData> = [];
    tabs.push({
      icon: "",
      name: "chat.threads",
      tab: this.getUrlPrefix("chat") + "chat-threads",
      active: currentType == "threads" ? true : false
    });
    tabs.push({
      icon: "",
      name: "chat.users",
      tab: this.getUrlPrefix("chat") + "chat",
      active: currentType == "users" ? true : false
    });
    log.debug("getChatTabs: current=" + currentType + ", result=", tabs);
    return tabs;
  }

  /**
   * Zwraca zakładki społecznościowe
   *
   * @returns {Promise<Array<SegmentTabsData>>}
   */
  async getSocialTabs(currentType: string = ""): Promise<Array<SegmentTabsData>> {
    let data = await this.getConferenceConfig();
    let tabs: Array<SegmentTabsData> = [];
    if (data.facebook)
      tabs.push({
        tab: this.getUrlPrefix("social") + "social/facebook",
        icon: "logo-facebook",
        name: "Facebook",
        active: currentType == "facebook" ? true : false
      });
    if (data.youtube)
      tabs.push({
        tab: this.getUrlPrefix("social") + "social/youtube",
        icon: "logo-youtube",
        name: "Youtube",
        active: currentType == "youtube" ? true : false
      });
    if (data.twitter)
      tabs.push({
        tab: this.getUrlPrefix("social") + "social/twitter",
        icon: "logo-twitter",
        name: "Twitter",
        active: currentType == "twitter" ? true : false
      });
    log.debug("getSocialTabs: current=" + currentType + ", result=", tabs);
    return tabs;
  }

  getUrlPrefix(tab) {
    let prefix = '';
    if (this.appConfig.Config.Application.TabsEnabled)
      prefix = '/tabs/';
    if (tab == "more") return prefix;
    return prefix + tab + "/";
  }

  /**
   * @returns Promise
   *
   * @param  {boolean} returnOfflineContent=false Czy zwrócić dane offline nawet gdy są nieaktualne
   */
  async getMenuItems(): Promise<Array<MenuItemData>> {
    log.debug("getMenuItems: start");
    let conferenceConfig = await this.getConferenceConfig(false);
    let result = conferenceConfig.modules[await this.appConfig.getLang()];
    let lang = await this.appConfig.getLang();
    let modules = [];
    let element, element2;
    let startInserterd = false;
    if (!result) {
      result = DEFAULT_MENU[lang];
      log.error("getMenuItems: error while downloading menu position, setting default", result);
    }
    //console.log('getMenuItems: items', result);

    for (let x = 0; x < result.length; x++) {

      if (result[x].name == "info" || result[x].name == "map") {

        
        if (result[x].name == "info")
          modules.push({
            title: result[x].name.charAt(0).toUpperCase() + result[x].name.slice(1),
            type: "divider",
            infokiosk: result[x].infokiosk,
            enabled: result[x].menu,
            name: result[x].name
          });
        try {
          element = conferenceConfig.pages.menu[result[x].name][lang];
          element2 = conferenceConfig.pages.pages[result[x].name][lang];
        } catch (error) {
          console.error("getMenuItems: submenu unsupported format for sumbemnus pages");
          element = [];
          element2 = [];
        }

        for (let y = 0; y < element.length; y++)
          for (let z = 0; z < element2[element[y].name].length; z++) {
            if (!startInserterd){
              modules.push({
                url: this.getUrlPrefix("home"),
                icon: 'home',
                type: "submenu",
                parent: "info",
                title: 'Start',
                infokiosk: true,
                enabled: true,
                name: 'Start'
              });
              startInserterd = true;
            }
            modules.push({
              url: this.getUrlPrefix("home") + "static/" + result[x].name + "/" + element[y].name + "/" + z,
              icon: element[y].icon,
              type: "submenu",
              parent: "info",
              title: element2[element[y].name][z].title_alt,
              infokiosk: true,
              enabled: true,
              name: element[y].name
            });
          }
      }
    }
    for (let x = 0; x < result.length; x++) {
      if (result[x].name == "info" || result[x].name == "map") continue;
            //brzydkie ręczne wyłączenie opcji dla infokiosków
            if (this.appConfig.isInfoKioskMode()){        
              if (result[x].name == "settings")
                {          
                  continue;
                }
            }
      if (result[x].name == "social") {
        modules.push({
          title: result[x].name.charAt(0).toUpperCase() + result[x].name.slice(1),
          type: "divider",
          infokiosk: result[x].infokiosk,
          enabled: result[x].menu,
          name: result[x].name
        });
        if (conferenceConfig.youtube)
          modules.push({
            url: this.getUrlPrefix("social") + "social/youtube",
            icon: "logo-youtube",
            type: "submenu",
            parent: "social",
            title: "Youtube",
            infokiosk: true,
            enabled: true,
            name: "social"
          });
        if (conferenceConfig.facebook)
          modules.push({
            url: this.getUrlPrefix("social") + "social/facebook",
            icon: "logo-facebook",
            type: "submenu",
            parent: "social",
            title: "Facebook",
            infokiosk: true,
            enabled: true,
            name: "social"
          });
        if (conferenceConfig.twitter)
          modules.push({
            url: this.getUrlPrefix("social") + "social/twitter",
            icon: "logo-twitter",
            type: "submenu",
            parent: "social",
            title: "Twitter",
            infokiosk: true,
            enabled: true,
            name: "social"
          });
      } else {
        modules.push({
          title: result[x].name.charAt(0).toUpperCase() + result[x].name.slice(1),
          url: this.getUrlPrefix("more") + result[x].name,
          type: "menu",
          infokiosk: result[x].infokiosk,
          enabled: result[x].menu,
          name: result[x].name
        });
      }
    }
    return modules;
  }

  /**
   * @returns Promise
   * @param  {boolean} returnOfflineContent=false Czy zwrócić dane offline nawet gdy są nieaktualne
   */
  async getMenu(forceReload: boolean = false): Promise<Array<MenuItemData>> {
    log.debug("getMenu: start");
    if (forceReload) {
      log.debug("buildMenu: forceReload");
      this.menu = undefined;
    }
    if (this.menu) {
      log.debug("getMenu: return from memory");
      return this.menu;
    }

    let modules = await this.getMenuItems();
    let pages = [];
    let isLogged = this.userAuth.isUserLogged();
    log.debug("getMenu: generating new menu ...", isLogged);
    for (let i: number = 0; i < modules.length; ++i) {
      let element = modules[i];
      if (isLogged && (element.name == "login" || element.name == "account" || element.name == "logout"))
        element.enabled = false;
      if (
        !isLogged &&
        (element.name == "login" || element.name == "logout" || element.name == "account" || element.name == "chat")
      )
        element.enabled = false;

      if (element.name == "chat" && isLogged){
        if (! await this.getChatPermission())
          element.enabled = false;
      }
      pages.push(element);
    }
    /* @todo zrobić to lepiej gdzie indziej */
    if (this.appConfig.Config.Application.Multi)
      pages.push({
        title: "ConferenceChange",
        url: "/conference-select",
        type: "menu",
        active: false,
        enabled: true,
        infokiosk: false
      });
    log.debug("getMenu: isLogged=" + isLogged + ", pages=", pages);
    this.menu = pages;
    return this.menu;
  }
  /**
   * Przetwarza tekst
   *
   * @param  {ArticleData} articleData
   * @returns ArticleHtmlData
   */
  processArticle(articleData: ArticleData): ArticleHtmlData {
    let result: ArticleHtmlData;

    if (articleData.movie_data && articleData.movie_data.length) {
      let search: string, replace: string;
      for (let x = 0; x < articleData.movie_data.length; x++) {
        search = "{film;" + Number(articleData.movie_data[x].id) + "}";
        log.debug("processArticle: search for movie: " + search);
        replace =
          '<div class="moviePlayer"><img src="' +
          articleData.movie_data[x].thumb +
          '" data-movie-src="' +
          articleData.movie_data[x].src +
          '" /><ion-icon name="play" data-movie-src="' +
          articleData.movie_data[x].src +
          '"></ion-icon></div>';
        articleData.body = articleData.body.replace(search, replace);

        search = "{film;" + articleData.movie_data[x].id + "}";
        log.debug("processArticle: search for movie: " + search);
        articleData.body = articleData.body.replace(search, replace);
      }
    }
    if (articleData.youtube_data && articleData.youtube_data.length) {
      let search: string, replace: string;
      for (let x = 0; x < articleData.youtube_data.length; x++) {
        search = "{youtube;" + articleData.youtube_data[x].id + "}";
        log.debug("processArticle: search for movie: " + search);
        replace =
          '<div class="moviePlayer"><img src="' +
          articleData.youtube_data[x].thumb +
          '" data-movie-youtube-src="' +
          articleData.youtube_data[x].id +
          '" /><ion-icon name="play" data-movie-youtube-src="' +
          articleData.youtube_data[x].id +
          '"></ion-icon></div>';
        articleData.body = articleData.body.replace(search, replace);
      }
    }
    result = <ArticleHtmlData>articleData;
    result.bodyHtml = this.sanitizer.bypassSecurityTrustHtml(result.body);
    result.headHtml = this.sanitizer.bypassSecurityTrustHtml(result.head);
    return result;
  }

  /**
   * Pobiera element listy ze spolecznosci
   *
   * @param  {string='news'} listType
   * @param  {boolean=false} forceRequest
   * @returns Promise
   */
  async getSocialList(listType: string, forceRequest: boolean = false): Promise<Array<any>> {
    await this.checkToken();
    let workspace;
    switch (listType) {
      case this.ELEMENT_TYPE_YOUTUBE:
        workspace = this.appConfig.Config.API.methods.Social.listItemsYoutube;
        break;
      case this.ELEMENT_TYPE_TWITTER:
        workspace = this.appConfig.Config.API.methods.Social.listItemsTwitter;
        break;
      case this.ELEMENT_TYPE_FACEBOOK:
        workspace = this.appConfig.Config.API.methods.Social.listItemsFacebook;
        break;
      default:
        console.error("getSocialList: unsupported type=", listType);
        return undefined;
    }
    if (workspace) {
      let lang = await this.appConfig.getLang();

      let data: APIResponse;
      try {
        data = await this.apiCall.cacheCall(
          workspace.class,
          workspace.method,
          [await this.appConfig.getAppId(), lang],
          this.appToken.getAccessTokenString(),
          forceRequest
        );
      } catch (error) {
        log.error("getSocialList", error);
        this.goToErrorPage("api");
        return null;
      }

      log.debug("getElementsList: type=" + listType + ", forceRequest=" + forceRequest, data.getContent());
      return data.getContent();
    }
    return null;
  }

  /**
   * Pobiera element listy
   *
   * @param  {string='news'} listType
   * @param  {boolean=false} forceRequest
   * @returns Promise
   */
  async getElementsList(
    listType: string = this.ELEMENT_TYPE_NEWS,
    forceRequest: boolean = false
  ): Promise<Array<ArticleData>> {
    await this.checkToken();
    let workspace;
    switch (listType) {
      case this.ELEMENT_TYPE_NEWS:
        workspace = this.appConfig.Config.API.methods.News.listItems;
        break;
      case this.ELEMENT_TYPE_GALLERIES:
        workspace = this.appConfig.Config.API.methods.Galleries.listItems;
        break;
      case this.ELEMENT_TYPE_MOVIES:
        workspace = this.appConfig.Config.API.methods.Movies.listItems;
        break;
      default:
        console.error("getSocialList: unsupported type=", listType);
        return undefined;
    }
    if (workspace) {
      let lang = await this.appConfig.getLang();
      let data: APIResponse;
      try {
        data = await this.apiCall.cacheCall(
          workspace.class,
          workspace.method,
          [await this.appConfig.getAppId(), lang],
          this.appToken.getAccessTokenString(),
          forceRequest
        );
      } catch (error) {
        log.error("getElementList", error);
        this.goToErrorPage("api");
        return null;
      }

      log.debug("getElementsList: type=" + listType + ", forceRequest=" + forceRequest, data.getContent());
      return data.getContent();
    }
    return null;
  }
  /**
   * Pobiera szczegóły pojedynczego elementu (newsa/filmu/galerii)
   * @param  {string='news'} listType
   * @param  {number} id
   * @param  {boolean=false} forceRequest
   * @returns Promise
   */
  async getElement(elementType: string = "news", id: number, forceRequest: boolean = false): Promise<ArticleHtmlData> {
    let lang = await this.appConfig.getLang();

    let workspace;
    switch (elementType) {
      case this.ELEMENT_TYPE_NEWS:
        workspace = this.appConfig.Config.API.methods.News.getItem;
        break;
      case this.ELEMENT_TYPE_GALLERIES:
        workspace = this.appConfig.Config.API.methods.Galleries.getItem;
        break;
      case this.ELEMENT_TYPE_MOVIES:
        workspace = this.appConfig.Config.API.methods.Movies.getItem;
        break;
    }

    await this.checkToken();
    let data: APIResponse;
    try {
      data = await this.apiCall.cacheCall(
        workspace.class,
        workspace.method,
        [await this.appConfig.getAppId(), id, lang],
        this.appToken.getAccessTokenString(),
        forceRequest
      );
    } catch (error) {
      log.error("getElement: error", error);
      this.goToErrorPage("api");
      return undefined;
    }

    log.debug("getElement: type=" + elementType, data.getContent());
    return this.processArticle(data.getContent());
  }
  /**
   * Pobiera stronę statyczną
   *
   * @param  {string} menuName Nazwa pozycji menu
   * @param  {string} pageType Typ strony (modulu)
   * @param  {number=0} pageIndex Index strony
   * @returns Promise
   */
  async getStaticPage(menuName: string, pageType: string, pageIndex: number = 0): Promise<ArticleHtmlData> {
    let cfg = await this.getConferenceConfig();
    let lang = await this.appConfig.getLang();
    let content;
    try {
      content = cfg.pages.pages[menuName][lang][pageType][pageIndex];
    } catch (error) {
      log.error("getStaticPage: Error no-content for type=" + pageType + ", idx=" + pageIndex + ".menu=" + menuName, error);
      return undefined;
    }

    log.debug("getStaticPage: type=" + pageType + ", idx=" + pageIndex + ".menu=" + menuName, content);
    return this.processArticle(content);
  }
  /**
   * @param  {boolean=false} forceRequest
   * @returns Promise
   */
  async getNewsList(forceRequest: boolean = false): Promise<Array<ArticleData>> {
    return this.getElementsList(this.ELEMENT_TYPE_NEWS, forceRequest);
  }

  /**
   * @param  {boolean=false} forceRequest
   * @returns Promise
   */
  async getGalleriesList(forceRequest: boolean = false): Promise<Array<ArticleData>> {
    return this.getElementsList(this.ELEMENT_TYPE_GALLERIES, forceRequest);
  }

  /**
   * @param  {boolean=false} forceRequest
   * @returns Promise
   */
  async getMoviesList(forceRequest: boolean = false): Promise<Array<ArticleData>> {
    return this.getElementsList(this.ELEMENT_TYPE_MOVIES, forceRequest);
  }
  /**
   * @param  {number} id
   * @param  {boolean=false} forceRequest
   * @returns Promise
   */
  async getNews(id: number, forceRequest: boolean = false): Promise<ArticleHtmlData> {
    return this.getElement(this.ELEMENT_TYPE_NEWS, id, forceRequest);
  }

  /**
   * @param  {number} id
   * @param  {boolean=false} forceRequest
   * @returns Promise
   */
  async getGallery(id: number, forceRequest: boolean = false): Promise<ArticleHtmlData> {
    return this.getElement(this.ELEMENT_TYPE_GALLERIES, id, forceRequest);
  }

  /**
   * @param  {number} id
   * @param  {boolean=false} forceRequest
   * @returns Promise
   */
  async getMovie(id: number, forceRequest: boolean = false): Promise<ArticleHtmlData> {
    return this.getElement(this.ELEMENT_TYPE_MOVIES, id, forceRequest);
  }

  /**
   * Pobiera listę partnerów/sponsorów w postaci kodu HTML do wyświetlenia
   *
   * @param forceRequest
   */
  async getPartners(forceRequest: boolean = false): Promise<string> {
    await this.checkToken();
    let workspace = this.appConfig.Config.API.methods.Partners.listItems;
    let lang = await this.appConfig.getLang();
    log.debug("getPartners");
    let result: APIResponse;
    try {
      result = await this.apiCall.cacheCall(
        workspace.class,
        workspace.method,
        [await this.appConfig.getAppId(), lang],
        this.appToken.getAccessTokenString(),
        forceRequest
      );
    } catch (error) {
      log.error("getPartners: error", error);
      this.goToErrorPage("api");
      return undefined;
    }

    let partners = <PartnersData>result.getContent();
    return this.strip_tags(partners.html, "<div><br><br /><img><h2>");
  }

  /**
   * Pobiera listę sesji z prelegentami
   *
   * @param forceRequest
   */
  async getSessions(forceRequest: boolean = false): Promise<SessionsData> {
    if (!forceRequest && this.sessionsData) {
      log.debug("getSessions: load from memory");
      return this.sessionsData;
    }
    await this.checkToken();
    let workspace = this.appConfig.Config.API.methods.Sessions.listItems;
    let lang = await this.appConfig.getLang();
    let result: APIResponse;
    try {
      result = await this.apiCall.cacheCall(
        workspace.class,
        workspace.method,
        [await this.appConfig.getAppId(), true, lang],
        this.appToken.getAccessTokenString(),
        forceRequest, true, undefined, undefined, false        
      );
    } catch (error) {
      log.error("getSessions: error", error);
      this.goToErrorPage("api");
      return undefined;
    }

    log.debug("getSessions: load from API", result);
    this.sessionsData = <SessionsData>result.getContent();
    return this.sessionsData;
  }
  /**
   * Pobiera szczegolowe informacje o zalogowany uzytkowniku
   *
   * @param  {boolean=false} forceRequest
   * @returns Promise
   */
  async getUserDetail(forceRequest: boolean = false): Promise<UserProfileData> {
    if (!forceRequest && this.userDetail) return this.userDetail;
    let token: Token = await this.userAuth.getToken();
    if (!token) {
      log.warn("getUserDetail: user is not logged!");
      return undefined;
    }

    let workspace = this.appConfig.Config.API.methods.User.getUserDetail;
    let lang = await this.appConfig.getLang();
    let result: APIResponse;

    try {
      result = await this.apiCall.cacheCall(
        workspace.class,
        workspace.method,
        [true, lang],
        token.getAccessTokenString(),
        forceRequest
      );
    } catch (error) {
      log.error("getUserToken: error", error);
      this.goToErrorPage("api");
      return undefined;
    }

    log.debug("getUserDetail", result);
    let content = result.getContent();
    if (content.image === undefined || content.image.length == 0) content.image = undefined;
    this.userDetail = <UserProfileData>content;
    log.debug("getUserDetail:", this.userDetail);
    return this.userDetail;
  }

  /**
   * Wysyła zadanie zmian profilu uzytkownika
   *
   * @param {string} firstname
   * @param {string} lastname
   * @param {string} position
   * @param {string} company
   * @returns {Promise<APIResponse>}
   *
   * @memberOf UserData
   */
  async setUserProfile(firstname: string, lastname: string, position: string, company: string): Promise<APIResponse> {
    let token: Token = await this.userAuth.getToken();
    if (!token) {
      log.warn("setUserProfile: user is not logged");
      return undefined;
    }
    let workspace = this.appConfig.Config.API.methods.User.setUserProfile;
    let result: APIResponse;

    try {
      result = await this.apiCall.cacheCall(
        workspace.class,
        workspace.method,
        [firstname, lastname, position, company],
        token.getAccessTokenString(),
        true,
        false
      );
    } catch (error) {
      log.error("setUserProfile error", error);
      return undefined;
    }

    log.debug(
      "setUserProfile firstname=" +
        firstname +
        ", lastname=" +
        lastname +
        ", position=" +
        position +
        ", company=" +
        company +
        ": result=" +
        result
    );
    return result;
  }

  /**
   * Wysyla ządanie zmian obrazka profilowego uzytkownik
   *
   * @param {string} image Zakodowany base64 obrazek
   * @returns {Promise<APIResponse>}
   *
   * @memberOf UserData
   */
  async setUserProfileImage(image: string): Promise<APIResponse> {
    let token: Token = await this.userAuth.getToken();
    let workspace = this.appConfig.Config.API.methods.User.setUserProfileImage;
    let result: APIResponse;
    try {
      result = await this.apiCall.cacheCall(
        workspace.class,
        workspace.method,
        [image],
        token.getAccessTokenString(),
        true,
        false
      );
    } catch (error) {
      log.error("setUserProfileImage: error", error);
    }
    log.debug("setUserProfileImage: result=" + result);
    return result;
  }

  /**
   * Czu uzytkownik jest zalogowany
   * @returns boolean
   */
  isUserLogged(): boolean {
    return this.userAuth.isUserLogged();
  }

  /**
   * Pobiera szczegolowe informacje o zalogowany uzytkowniku
   *
   * @param  {boolean=false} forceRequest
   * @returns Promise
   */
  async getUserStatusForEvent(forceRequest: boolean = false): Promise<UserEventData> {
    let token: Token = await this.userAuth.getToken();
    if (!token) {
      log.warn("getUserStatusForEvent: user is not logged");
      return undefined;
    }
    let workspace = this.appConfig.Config.API.methods.User.getUserStatusForEvent;
    let lang = await this.appConfig.getLang();
    let result: APIResponse;
    try {
      result = await this.apiCall.cacheCall(
        workspace.class,
        workspace.method,
        [await this.appConfig.getAppId(), lang],
        token.getAccessTokenString(),
        forceRequest,
        true
      );
    } catch (error) {
      log.error("getUserStatusForEvent: error", error);
      this.goToErrorPage("api");
      return undefined;
    }
    log.debug("getUserStatusForEvent:", result);
    return <UserEventData>result.getContent();
  }
  /**
   * Czy uzytkownik jest zaakceptowany na konferencje
   *
   * @param  {boolean=false} forceRequest
   * @returns Promise
   */
  async isUserApprovedForConference(forceRequest: boolean = false): Promise<boolean> {
    let data = await this.getUserStatusForEvent(forceRequest);
    if (!data) {
      log.debug("isUserApprovedForConference: false, no data");
      return false;
    }
    if (!data.status_id) {
      log.debug("isUserApprovedForConference: false, no status_id");
      return false;
    }
    if (data.status_id >= 3 && Number(data.access_id) >= 0) {
      log.debug("isUserApprovedForConference: true, status_id=" + data.status_id + ", access=" + data.access_id);
      return true;
    }
    log.debug("isUserApprovedForConference: false, status_id=" + data.status_id + ", access=" + data.access_id);
    return false;
  }

  /**
   * @param  {boolean=false} automatic
   * @returns Promise
   */
  async logout(automatic: boolean = false): Promise<any> {
    this.userDetail = undefined;
    this.userToken = undefined;
    this.menu = undefined;
    await this.userAuth.logout(automatic);
    return true;
  }

  /**
   * Logowanie uzytkonwika
   *
   * @param  {string} username
   * @param  {string} password
   * @returns Promise
   */
  async login(username: string, password: string): Promise<Token> {
    let token: Token = await this.userAuth.login(username, password);
    if (!token) return undefined;
    let profile: UserProfileData = await this.getUserDetail(true);
    let status: UserEventData = await this.getUserStatusForEvent(true);

    log.info("login profil=", profile);
    log.info("login status=", status);
    return token;
  }

  async getUserCredentials(): Promise<UserCredentialsData> {
    try {
      let result = await this.userAuth.getUserCredentialsFromStorage();
      return result;
    } catch (error) {
      //nie sa jeszcze zapisane
      return undefined;
    }
  }

  /**
   * Usuwa zapisane dane logowania uzytkownika
   *
   * @returns Promise
   */
  async cleanUserCredentials(): Promise<any> {
    return this.userAuth.cleanUserCredentials();
  }

  /**
   * JSowy odpowiednik funkcji strip_tags z PHP
   * Usuwa z kody HTML wszystkie tagi z wyjątkiem tych przekazanych w parametrze allowed
   *
   * @param  {string} input Wejsciowy kod HTML
   * @param  {string} allowed Dozwolone tagi (np '<p><div>')
   * @returns string
   */
  strip_tags(input: string, allowed: string): string {
    // eslint-disable-line camelcase
    //  discuss at: http://locutus.io/php/strip_tags/
    // original by: Kevin van Zonneveld (http://kvz.io)
    // improved by: Luke Godfrey
    // improved by: Kevin van Zonneveld (http://kvz.io)
    //    input by: Pul
    //    input by: Alex
    //    input by: Marc Palau
    //    input by: Brett Zamir (http://brett-zamir.me)
    //    input by: Bobby Drake
    //    input by: Evertjan Garretsen
    // bugfixed by: Kevin van Zonneveld (http://kvz.io)
    // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
    // bugfixed by: Kevin van Zonneveld (http://kvz.io)
    // bugfixed by: Kevin van Zonneveld (http://kvz.io)
    // bugfixed by: Eric Nagel
    // bugfixed by: Kevin van Zonneveld (http://kvz.io)
    // bugfixed by: Tomasz Wesolowski
    //  revised by: Rafał Kukawski (http://blog.kukawski.pl)
    //   example 1: strip_tags('<p>Kevin</p> <br /><b>van</b> <i>Zonneveld</i>', '<i><b>')
    //   returns 1: 'Kevin <b>van</b> <i>Zonneveld</i>'
    //   example 2: strip_tags('<p>Kevin <img src="someimage.png" onmouseover="someFunction()">van <i>Zonneveld</i></p>', '<p>')
    //   returns 2: '<p>Kevin van Zonneveld</p>'
    //   example 3: strip_tags("<a href='http://kvz.io'>Kevin van Zonneveld</a>", "<a>")
    //   returns 3: "<a href='http://kvz.io'>Kevin van Zonneveld</a>"
    //   example 4: strip_tags('1 < 5 5 > 1')
    //   returns 4: '1 < 5 5 > 1'
    //   example 5: strip_tags('1 <br/> 1')
    //   returns 5: '1  1'
    //   example 6: strip_tags('1 <br/> 1', '<br>')
    //   returns 6: '1 <br/> 1'
    //   example 7: strip_tags('1 <br/> 1', '<br><br/>')
    //   returns 7: '1 <br/> 1'
    // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
    allowed = (((allowed || "") + "").toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join("");
    var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
    var commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
    return input.replace(commentsAndPhpTags, "").replace(tags, function($0, $1) {
      return allowed.indexOf("<" + $1.toLowerCase() + ">") > -1 ? $0 : "";
    });
  }

  /**
   * Pobiera listę sesji z agendy dla filtra danych/czasu/ściezki tematycznej
   *
   * @param {any} dayIndex
   * @param {string} [queryText='']
   * @param {any} [excludePlaces=[]]
   * @param {string} [segment='all']
   * @param {any} [excludeTracks=[]]
   * @param {boolean} flattenList
   * @returns
   *
   * @memberOf ConferenceData
   */
  async getTimeline(
    queryText: string = "",
    excludePlaces: Array<number> = [],
    segment = "all",
    excludeTracks: Array<number> = [],
    excludeDays: Array<number> = [],
    flattenList = false
  ): Promise<TimelineData> {
    log.debug("getTimeLine: q=" + queryText);
    log.debug('getTimeLine: flatList=' + flattenList);
    let data = await this.getSessions();
    let totalShownSessions: number = 0;
    let sessionListSession: TimeLineSessionData;
    let flatList: Array<Array<TimeLineSessionData>> = [];
    let timeline: TimelineData = {
      shownSessions: 0,
      days: []
    };

    queryText = queryText.toLowerCase().replace(/,|\.|-/g, " ");
    let queryWords = queryText.split(" ").filter(w => !!w.trim().length);
    let favorites = await this.getFavorites();
    log.debug("getTimeline: favorites: ", favorites);

    for (let day = 0; day < data.schedule.length; day++) {
      let hide = false;
      let dayShownSessions = 0;
      if (excludeDays.length && excludeDays.indexOf(day) !== -1) hide = true;
      if (flattenList) flatList[day] = [];
      let date = data.schedule[day].date;
      timeline.days.push({
        shownSessions: 0,
        groups: [],
        hide: hide,
        date: date
      });
      for (let groupIdx = 0; groupIdx < data.schedule[day].groups.length; groupIdx++) {
        let hideGroup = false;
        let groupShownSessions = 0;
        timeline.days[day].groups.push({
          hide: hideGroup,
          sessions: [],
          date: data.schedule[day].groups[groupIdx].sessions[0].timeStart,
          time: data.schedule[day].groups[groupIdx].time
        });
        for (let sessionIdx = 0; sessionIdx < data.schedule[day].groups[groupIdx].sessions.length; sessionIdx++) {
          let hideSession = false;
          let sessionData: SessionData = data.sessionById[Number(data.schedule[day].groups[groupIdx].sessions[sessionIdx])];
          /* uzupelniam brakujace daty */
          sessionData.date = date;
          data.sessionById[Number(sessionData.id)].date = date;

          let session: TimeLineSessionData = await this.sessionConvert(sessionData, data, favorites);
          session.hide = hideSession;
          if (!session.hide && segment != "favorites")
            await this.filterSession(session, queryWords, excludePlaces, excludeTracks);
          if (segment == "favorites") {
            if (!favorites.length) session.hide = true;
            else session.hide = favorites.indexOf(session.id) == -1;
          }
          if (!session.hide) {
            sessionListSession = {
              date: session.date,
              favorite: session.favorite,
              hide: session.hide,
              id: session.id,
              name: session.name,
              placeName: session.placeName,
              timeStart: session.timeStart,
              timeEnd: session.timeEnd,
              trackColor: session.trackColor,
              trackName: session.trackName,
              tracks: []
            };
            if (flattenList) flatList[day].push(sessionListSession);
            else timeline.days[day].groups[groupIdx].sessions.push(sessionListSession);
            totalShownSessions++;
            dayShownSessions++;
            groupShownSessions++;
          }
        }
        if (groupShownSessions == 0) timeline.days[day].groups[groupIdx].hide = true;
      }
      timeline.days[day].shownSessions = dayShownSessions;
      if (dayShownSessions == 0) timeline.days[day].hide = true;
    }
    timeline.shownSessions = totalShownSessions;
    if (flattenList) {
      timeline.flatList = flatList;
     // log.error(flatList);
    }
    return timeline;
  }

  /**
   * Roszerza sesje o dodatkowe informacje
   *
   * @param  {SessionData} sessionData
   * @returns Promise
   */
  private async sessionConvert(
    sessionData: SessionData,
    data: SessionsData = undefined,
    favorites: Array<number> = undefined
  ): Promise<TimeLineSessionData> {
    if (data === undefined) data = await this.getSessions();
    if (favorites === undefined) {
      favorites = await this.getFavorites();
      log.debug("getTimeline: favorites: ", favorites);
    }
    let session: TimeLineSessionData = {
      ...sessionData,
      ...{
        hide: false,
        trackName: undefined,
        trackColor: undefined,
        favorite: false,
        placeName: undefined
      }
    };

    if (session.tracks) {
      let track = data.tracksById[Number(session.tracks[0])];
      session.trackName = track.name;
      session.trackColor = track.color;
    }
    if (session.location) session.placeName = data.places[Number(session.location)].name;
    session.favorite = favorites.indexOf(session.id) !== -1;
    return session;
  }

  /**
   *
   *
   * @param {any} session
   * @param {any} queryWords
   * @param {any} excludePlaces
   *
   *
   * @memberOf ConferenceData
   */
  async filterSession(session, queryWords, excludePlaces, excludeTracks) {
    // this.appConfig.log.debug('[conferenceData.filterSession] query, places, segment, tracks: ', queryWords, excludePlaces, segment, excludeTracks);
    /* gdy sesje uźytkownika nie uwzględniamy filtrów */

    let matchesQueryText = false;
    if (queryWords.length) {
      // of any query word is in the session name than it passes the query test
      queryWords.forEach(queryWord => {
        if (session.name.toLowerCase().indexOf(queryWord) > -1) {
          matchesQueryText = true;
        }
      });
    } else {
      // if there are no query words then this session passes the query test
      matchesQueryText = true;
    }

    if (!matchesQueryText) {
      session.hide = true;
      return;
    }

    // if any of the sessions tracks are not in the
    // exclude tracks then this session passes the track test
    let matchesPlaces = false;
    if (excludePlaces.length) {
      if (excludePlaces.indexOf(session.location) === -1) matchesPlaces = true;
    } else matchesPlaces = true;

    if (!matchesPlaces) {
      session.hide = true;
      return;
    }

    let matchesTracks = false;
    if (session.tracks) {
      for (let x = 0; x < session.tracks.length; x++)
        if (excludeTracks.indexOf(session.tracks[x]) === -1) matchesTracks = true;
    }

    // this.appConfig.log.debug(excludePlaces, session.location, excludePlaces.indexOf(session.location), session.track.trackId, excludeTracks.indexOf(session.track.trackId),  matchesQueryText, matchesPlaces, matchesSegment, matchesTracks);
    // all tests must be true if it should not be hidden
    session.hide = !(matchesQueryText && matchesPlaces && matchesTracks);
  }

  /**
   * Zwraca listę ulubionych sesji
   *
   * @param  {} sessionId
   * @returns Promise
   */
  async getFavorites(): Promise<Array<number>> {
    if (!this.isUserLogged()) return [];
    let data = await this.getUserStatusForEvent(false);
    if (!data.sessions || !data.sessions.length) return [];
    return data.sessions;
  }

  /**
   * Pobiera listę ściezek tematycznych agendy
   *
   * @returns {Promise<string[]>}
   *
   * @memberOf ConferenceData
   */
  async getTracks(): Promise<Array<number>> {
    let data = await this.getSessions();
    return data.tracks;
  }

  /**
   * Pobiera sesję o danym identyfikatorze
   *
   * @param {any} sessionId
   * @returns {Promise<any>}
   *
   * @memberOf ConferenceData
   */
  async getSessionById(sessionId: number): Promise<SessionData> {
    log.debug("getSessionById: " + sessionId);
    let data = await this.getSessions();
    return data.sessionById[Number(sessionId)];
  }

  /**
   * Pobiera sesję o danym identyfikatorze
   *
   * @param {any} sessionId
   * @returns {Promise<any>}
   *
   * @memberOf ConferenceData
   */
  async getSpeakerById(speakerId: number): Promise<SpeakerData> {
    log.debug("getSpeakerById: " + speakerId);
    let data = await this.getSessions();
    return data.speakersById[Number(speakerId)];
  }
  /**
   * Zwraca sesję z prelegentami
   * @param  {number} id
   * @returns Promise
   */
  async getSessionWithSpeakers(id: number): Promise<SessionWithSpeakersData> {
    let session: SessionWithSpeakersData;
    let data = await this.getSessions();
    let sessionData = await this.getSessionById(Number(id));
    let timelineSessionData = await this.sessionConvert(sessionData);
    session = {
      ...timelineSessionData,
      ...{ speakersData: [], locationData: undefined }
    };
    if (!session.trackColor) session.trackColor = "#FFFFFF";
    let speakersData: Array<SpeakerData> = [];
    if (data.speakersById)
      for (let x = 0; x < sessionData.speakers.length; x++) {
        if (data.speakersById[Number(sessionData.speakers[x])])
          speakersData.push(data.speakersById[Number(sessionData.speakers[x])]);
      }
    if (session.location) session.locationData = await this.getLocationById(session.location);
    session.speakersData = speakersData;
    return session;
  }
  /**
   * Zwraca prelegenta wraz z sesjami, w ktorych bierze udzial
   * @param  {number} id
   * @returns Promise
   */
  async getSpeakerWithSessions(id: number): Promise<SpeakerWithSessionsData> {
    let speaker: SpeakerWithSessionsData;
    let data = await this.getSessions();
    speaker = {
      ...(<SpeakerData>data.speakersById[Number(id)]),
      ...{ sessionsData: [] }
    };
    let session: SessionData;
    let timeLineSession: TimeLineSessionData;

    for (let x = 0; x < speaker.sessions.length; x++) {
      session = await this.getSessionById(Number(speaker.sessions[x]));
      timeLineSession = await this.sessionConvert(session);
      speaker.sessionsData.push(timeLineSession);
    }
    return speaker;
  }

  /**
   * Pobiera lokację o danym identyfikatorze
   *
   * @param {any} locationId
   * @returns {Promise<any>}
   *
   * @memberOf ConferenceData
   */
  async getLocationById(locationId): Promise<PlacesData> {
    let data = await this.getSessions();
    return data.places[Number(locationId)];
  }

  /**
   * Pobiera listę prelegentów
   *
   * @param {boolean} [forceRequest=false]
   * @returns {Promise<Array<SpeakerData>>}
   *
   * @memberOf ConferenceData
   */
  async getSpeakers(forceRequest = false): Promise<Array<SpeakerData>> {
    if (this.speakers) {
      log.debug("getSpeakers return from memory");
      return this.speakers;
    }
    let data = await this.getSessions();
    let speakers: Array<SpeakerData> = [];

    if (data.speakers)
      for (let x = 0; x < data.speakers.length; x++) {
        let speaker = data.speakersById[Number(data.speakers[x])];
        speakers.push({
          id: speaker.id,
          position: speaker.position,
          company: speaker.company,
          profilePic: speaker.profilePic,
          lastName: speaker.lastName,
          letter: speaker.letter,
          name: speaker.name,
          sessions: speaker.sessions
        });
      }
    /*
		speakers.sort((a, b) => {
			let aName = a.name.split(' ').pop();
			let bName = b.name.split(' ').pop();
			return aName.localeCompare(bName);
		});
		*/
    this.speakers = speakers;

    return this.speakers;
  }

  /**
   * Filtruje prelegenta na podstawie ciągu znaków queryText
   * Wyszukuje w imieniu, nazwisku, stanowisku i nazwie firmy
   *
   * @param {string} queryText
   * @returns {Promise<SpeakerSearchResultData>}
   *
   * @memberOf ConferenceData
   */
  async filterSpeakers(queryText: string, strict: boolean = false): Promise<SpeakerSearchResultData> {
    log.debug("filterSpeakers q=" + queryText);
    let speakers: Array<SpeakerData> = await this.getSpeakers();
    for (let x = 0; x < speakers.length; x++) speakers[x].hide = false;
    if (!queryText) {
      log.debug("no search characters, returning full list");
      return {
        shown: speakers.length,
        speakers: speakers
      };
    }
    let shown: number = 0;
    let strUtils: StringUtils;
    let result: SpeakerSearchResultData = {
      speakers: [],
      shown: 0
    };
    queryText = queryText
      .toLowerCase()
      .trim()
      .replace(/,|\.|-/g, " ");
    if (!strict) {
      strUtils = new StringUtils();
      queryText = strUtils.removeAccents(queryText);
    }
    let queryWords = queryText.split(" ").filter(w => !!w.trim().length);
    let matchesQueryText = true;
    for (let i = 0; i < speakers.length; i++) {
      let speaker = speakers[i];

      if (!speaker.searchText) {
        if (speaker.position == null) speaker.position = "";
        if (speaker.company == null) speaker.company = "";
        speaker.searchText = (speaker.name + " " + speaker.position + " " + speaker.company).toLowerCase().trim();
        if (!strict) speaker.searchText = strUtils.removeAccents(speaker.searchText);
      }

      if (queryWords.length) {
        for (let j = 0; j < queryWords.length; j++) {
          let queryWord = queryWords[j];
          matchesQueryText = false;
          if (speaker.searchText.indexOf(queryWord) > -1) matchesQueryText = true;
          else {
            matchesQueryText = false;
            break;
          }
        }
      }
      if (!matchesQueryText) continue;
      result.speakers.push(speaker);
    }
    log.debug("filterSpeakers search results: " + shown);
    result.shown = result.speakers.length;
    return result;
  }
  /**
   * Wyświetla komunikat dla uzytkownika
   *
   * @param  {string} message
   * @param  {string} subheader
   * @param  {string='Alert'} header
   * @param  {Array<string>=['OK']} buttons
   */
  async presentAlert(
    message: string,
    header: string = "Alert",
    subheader: string = undefined,
    buttons: Array<string> = ["OK"]
  ) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subheader,
      message: message,
      buttons: ["OK"]
    });

    await alert.present();
  }
  /**
   * @param  {string} message
   * @param  {number=2000} duration
   * @param  {boolean=false} showCloseButton
   * @param  {string='OK'} closeButtonText
   * @param  {"bottom"} position
   */
  async presentToast(
    message: string,
    duration: number = 2000,
    showCloseButton: boolean = false,
    closeButtonText: string = "OK"
  ) {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      showCloseButton: showCloseButton,
      position: "bottom",
      closeButtonText: closeButtonText
    });
    toast.present();
  }

  /**
   * Loguje uzytkownika do facebooka
   * @returns Promise
   */
  async loginWithFacebook(): Promise<boolean> {
    let res = await this.userAuth.loginWithFacebook();

    if (res.error) {
      this.presentAlert(
        await this.appConfig.translator.get(res.error).toPromise(),
        await this.appConfig.translator.get("facebook.titleLoginFailed").toPromise()
      );
      log.error("loginWithFacebook error: ", res.error);
      return false;
    }
    return true;
  }

  /**
   * Loguje uzytkownika do linkedIna
   * @returns Promise
   */
  async loginWithLinkedIn(): Promise<boolean> {
    let res = await this.userAuth.loginWithLinkedIn();

    if (res.error) {
      await this.presentAlert(
        await this.appConfig.translator.get(res.error).toPromise(),
        await this.appConfig.translator.get("linkedin.titleLoginFailed").toPromise()
      );
      log.error("loginWithLinkedIn error: ", res.error);
      return false;
    }
    return true;
  }

  async getAppsList(forceRequest: boolean = false): Promise<Array<AppsListData>> {
    log.debug("getAppsList: start forceRequest=" + forceRequest);
    let workspace = this.appConfig.Config.API.methods.Application.listApps;
    let content: Array<AppsListData>;

    try {
      await this.checkToken();
      let data: APIResponse;

      try {
        data = await this.apiCall.cacheCall(
          workspace.class,
          workspace.method,
          [],
          this.appToken.getAccessTokenString(),
          forceRequest
        );
      } catch (error) {
        log.error("getAppList: error", error);
        this.goToErrorPage("api");
        return undefined;
      }
      log.debug("getAppsList:", data);
      content = <Array<AppsListData>>data.getContent();
    } catch (error) {
      log.error("getAppsList: error", error);
      this.goToErrorPage("api");
      return undefined;
    }
    return content;
  }

  /**
   * Czy uytkownik ma uprawnienia do chata
   * @returns {Promise<boolean>}
   */
  async getChatPermission(forceRequest: boolean = false): Promise<boolean> {
    if (!this.isUserLogged()) {
      log.debug("getChatPermission: false, not logged");
      return false;
    }
    let data = await this.getUserStatusForEvent(forceRequest);
    log.debug("getChatPermission: " + data.communicator_enabled);
    return data.communicator_enabled;
  }

  /**
   * Czy uytkownik ma uprawnienia do chata - do trybu listy uzytkownikow
   * @returns {Promise<boolean>}
   */
  async getChatPermissionList(forceRequest: boolean = false): Promise<number> {
    if (!this.isUserLogged()) {
      log.debug("getChatPermissionList: false, not logged");
      return undefined;
    }
    let data = await this.getUserStatusForEvent(forceRequest);
    log.debug("getChatPermissionList: " + data.communicator_list);
    return data.communicator_list;
  }

  /**
   * Wypisuje uczestnika z sesji
   * @param  {number} sessionId
   */
  async removeFavorite(sessionId: number) {
    let favorites = await this.getFavorites();
    let index = favorites.indexOf(sessionId);
    if (index > -1) favorites.splice(index, 1);
    await this.conferenceRegister(favorites);
    this.appConfig.appEvents.publish(this.appConfig.appEvents.event.USER_CHANGE_SESSIONS);
  }

  /**
   * Zapisuje uczestnika na sesję
   *
   * @param  {number} sessionId
   */
  async addFavorite(sessionId: number) {
    let favorites = await this.getFavorites();
    favorites.push(sessionId);
    await this.conferenceRegister(favorites);
    this.appConfig.appEvents.publish(this.appConfig.appEvents.event.USER_CHANGE_SESSIONS);
  }

  /**
   * Rejestruję na konferencję, lub edytuje listę sesji gdy użytkownik
   * jest już zapisany
   *
   * @param {Array<number>} sessionsList
   * @returns {Observable<APIResponse>}
   *
   * @memberOf UserData
   */
  async conferenceRegister(sessionsList: Array<number>): Promise<boolean> {
    let token: Token = await this.userAuth.getToken();
    if (!token) {
      log.error("getUserStatusForEvent: user is not logged");
      return false;
    }
    let workspace = this.appConfig.Config.API.methods.User.conferenceRegister;
    let lang = await this.appConfig.getLang();
    let data = await this.getUserStatusForEvent();

    let result: APIResponse;
    try {
      result = await this.apiCall.cacheCall(
        workspace.class,
        workspace.method,
        [data.event_id, sessionsList, lang],
        token.getAccessTokenString(),
        true,
        false
      );
    } catch (error) {
      log.error("conferenceRegister: error", error);
    }

    await this.getUserStatusForEvent(true);
    log.debug("conferenceRegister:", result);
    return result.isSuccess();
  }

/**
 * Zwraca listę uzytkownikow z ktormi korespondował nasz
 * @param forceRequest 
 */
async getChatMessagesUsers(forceRequest = false): Promise<ChatUsersData> {
  if (forceRequest) this.usersList = undefined;

  if (this.usersList != undefined) {
    log.debug("getChatMessagesUsers Loaded " + this.usersList.users.length + " users from memory");
    return this.usersList;
  }

  let conferenceData = await this.getConferenceConfig();
  log.debug("getChatMessagesUsers: start downloading list for event_id ", conferenceData.event_id);
  let workspace = this.appConfig.Config.API.methods.Chat.getMessagesAdv;
  let token: Token = await this.userAuth.getToken();
  if (!token) {
    log.error("getChatMessagesUsers: user is not logged");
    this.goToErrorPage("api");
    return undefined;
  }
   
  let result: APIResponse;
  try {
    result = await this.apiCall.cacheCall(
      workspace.class,
      workspace.method,
      [conferenceData.event_id],
      token.getAccessTokenString(),
      forceRequest,
      true, undefined, undefined, false      
    );
  } catch (error) {
    log.error("getChatMessagesUsers: error", error);
    this.goToErrorPage("api");
    return undefined;
  }

let data = result.getContent();
if (!data || !data.inbox || !data.inbox.length)
  {
    log.debug('getChatMessagesUsers: empty', data);
    return undefined;
  }
  log.debug('getChatMessagesUsers: processing', data);
let chatUsersData: ChatUsersData = {
  users: [],
  usersById: [],
  usersByFirstLetter: null
};

for (let x=0; x<data.inbox.length; x++){
  let user = data.inbox[x].sender;
  chatUsersData.usersById[user.id] = {
    userid: user.id,
    company: user.company,
    communicationgranted: user.communicationgranted,
    firstname: user.firstname,
    lastname: user.lastname,
    image: user.image ? user.image : null,
    letter: 'A',
    position: user.position,
  };
}


  this.usersList = chatUsersData;
  log.debug('getChatMessagesUsers:', this.usersList);
  log.debug("getChatMessagesUsers: end downloading list for event_id users.length=", this.usersList.users.length);
  return this.usersList;
}

  /**
   * Pobiera liste wszystkich uzytkowników komunikatora
   *
   * @param {boolean} [forceRequest=false]
   * @returns {Observable<any>}
   *
   * @memberOf UserData
   */
  async getChatUsersList(forceRequest = false): Promise<ChatUsersData> {
    if (forceRequest) this.usersList = undefined;

    if (this.usersList != undefined) {
      log.debug("getChatUsersList: Loaded " + this.usersList.users.length + " users from memory");
      return this.usersList;
    }

    let conferenceData = await this.getConferenceConfig();
    log.debug("getChatUsesrList: start downloading list for event_id ", conferenceData.event_id);
    let workspace = this.appConfig.Config.API.methods.Chat.listUsers;
    let token: Token = await this.userAuth.getToken();
    if (!token) {
      log.error("getUserStatusForEvent: user is not logged");
      this.goToErrorPage("api");
      return undefined;
    }
    if (! await this.getChatPermissionList())
      return this.getChatMessagesUsers();
   
    let result: APIResponse;
    try {
      result = await this.apiCall.cacheCall(
        workspace.class,
        workspace.method,
        [conferenceData.event_id],
        token.getAccessTokenString(),
        forceRequest,
        true, undefined, undefined, false      
      );
    } catch (error) {
      log.error("getChatUsersList: error", error);
      this.goToErrorPage("api");
      return undefined;
    }

    this.usersList = <ChatUsersData>result.getContent();
    log.debug("getChatUsersList: end downloading list for event_id users.length=", this.usersList.users.length);
    return this.usersList;
  }

  /**
   * Filtruje uzytkownikow wobec filtra queryText - dla tych, dla których
   * nie zostały spełnione warunki wyszukania ustawiony jest paramter hide na true
   *
   * @param {string} [queryText='']
   * @param {boolean} [forceRequest=false]
   * @param {boolean} [strict = false]
   * @returns
   *
   * @memberOf UserData
   */
  async getChatUsers(
    queryText: string = "",
    forceRequest = false,
    strict: boolean = false
  ): Promise<ChatUsersSearchResultData> {
    log.debug("getChatUsers: query=" + queryText + " start");
    let users = await this.getChatUsersList(forceRequest);
    let result: ChatUsersSearchResultData = {
      users: []
    };
    let user: ChatUserData;
    let matchesQueryText = true;
    let strUtils: StringUtils;
    queryText = queryText.toLowerCase().replace(/,|\.|-/g, " ");
    if (!strict) {
      strUtils = new StringUtils();
      queryText = strUtils.removeAccents(queryText);
    }

    let queryWords = queryText.split(" ").filter(w => !!w.trim().length);
    log.warn(queryText, queryWords);
    for (let x = 0; x < users.users.length; x++) {
      user = users.usersById[Number(users.users[x])];
      if (!user) {
        log.warn("getChatUsers: brak uzytkownika o id=" + Number(users.users[x]));
        continue;
      }
      if (user.position == null) user.position = "";
      if (user.company == null) user.company = "";
      if (!user.searchText) {
        user.searchText =
          user.firstname.toLowerCase() +
          " " +
          user.lastname.toLowerCase() +
          " " +
          // user.position.toLowerCase() +
          // " " +
          user.company.toLowerCase();
        if (!strict) user.searchText = strUtils.removeAccents(user.searchText);
      }
      if (queryWords.length) {
        for (let y = 0; y < queryWords.length; y++) {
          let queryWord = queryWords[y];
          matchesQueryText = false;
          if (user.searchText.indexOf(queryWord) > -1) matchesQueryText = true;
          if (!matchesQueryText) break;
        }
      }
      if (!matchesQueryText) continue;
      result.users.push({
        ...user,
        ...{ hide: false }
      });
    }
    result.shown = result.users.length;
    log.debug("getChatUsers: query=" + queryText + ", found:" + result.shown);
    return result;
  }

  /**
   * Czy są nieprzeczytane wiadomości
   *
   * @param  {number=null} lastId
   * @returns Promise{number} liczba nowych wiadomosci
   */
  async getIsNewMessage(lastId: number = null): Promise<ChatMessagesData> {
    let token: Token = await this.userAuth.getToken();
    if (!token) {
      log.error("getIsNewMessage: user is not logged");
      return undefined;
    }
    log.debug("getIsNewMessage: start lastId=" + lastId);
    let workspace = this.appConfig.Config.API.methods.Chat.getMessages;
    let lang = await this.appConfig.getLang();
    let conferenceData = await this.getUserStatusForEvent();
    let result: APIResponse;
    try {
      result = await this.apiCall.cacheCall(
        workspace.class,
        workspace.method,
        [conferenceData.event_id, null, lastId],
        token.getAccessTokenString(),
        true,
        false,
        undefined,
        [{ name: "ignoreLoadingBar", value: "true" }]
      );
    } catch (error) {
      log.error("getIsNewMessage: error during API request", error);
      this.goToErrorPage("api");
      return undefined;
    }
    log.debug("getIsNewMessage: ", result.getContent());
    return <ChatMessagesData>result.getContent();
  }

  /**
   * Pobiera (z serwera API lub lokalnego cache;u) listę wiadomości uzytkownika
   *
   * @param {boolean} [forceRequest=false] Czy wymusić pobranie z serwera API
   * @param {string} [minDate=null] Pobierz wiadomosci nowsze od minDate
   * @param {number} [minID=null] Pobierz wiadomosci o Id większym niz minId
   * @returns {Promise<any>}
   *
   * @memberOf UserData
   */
  async getChatMessages(forceRequest = false, minDate: string = null, minID: number = null): Promise<PeopleListData> {
    let token: Token = await this.userAuth.getToken();
    if (!token) {
      log.error("getChatMessages: user is not logged");
      return undefined;
    }
    if (forceRequest) this.chatMessages = undefined;
    if (this.chatMessages != undefined) {
      log.debug("getChatMessages: return from memory", this.chatMessages);
      return this.chatMessages;
    }

    let workspace = this.appConfig.Config.API.methods.Chat.getMessages;
    let lang = await this.appConfig.getLang();
    let conferenceData = await this.getUserStatusForEvent();
    let result: APIResponse;
    let addHeaders = [];
    if (minID || minDate) addHeaders = [{ name: "ignoreLoadingBar", value: "true" }];
    try {
      result = await this.apiCall.cacheCall(
        workspace.class,
        workspace.method,
        [conferenceData.event_id, minDate, minID],
        token.getAccessTokenString(),
        true,
        true,
        undefined,
        addHeaders
      );
    } catch (error) {
      log.error("getChatMessages: error during API request", error);
      this.goToErrorPage("api");
      return undefined;
    }

    let chatMessages: ChatMessagesData = result.getContent();
    let users = await this.getChatUsersList();

    let person: PersonData;
    let people: PeopleListData = {
      persons: [],
      shown: 0
    };

    let user: ChatUserData;
    for (let x = 0; x < chatMessages.users.length; x++) {
      user = users.usersById[Number(chatMessages.users[x].userid)];
      if (!user) {
        log.error("getChatMessages: No such user: " + chatMessages.users[x].userid);
        continue;
      }
      if (!user.firstname) user.firstname = "";
      if (!user.lastname) continue;
      person = {
        name: user.firstname + " " + user.lastname,
        description: chatMessages.users[x].lastmessage,
        id: user.userid,
        profilePic: user.image,
        date: chatMessages.users[x].date,
        detailIcon: chatMessages.users[x].unread ? "radio-button-on" : undefined
      };
      people.persons.push(person);
    }

    people.persons.sort(function(a, b) {
      let c = new Date(b.date).getTime();
      let d = new Date(a.date).getTime();
      return c - d;
    });
    people.shown = people.persons.length;
    this.chatMessages = people;
    log.debug("getChatMessages: return from api", this.chatMessages);
    return this.chatMessages;
  }

  /**
   * Zwraca listę wiadomości dla wątku rozmowy
   *
   * @param {number} threadId Identyfikator watku
   * @param {string} [minDate=null] Pobierz nowsze wiadomości niz minDate
   * @param {number} [minID=null] Pobierz wiadomosci o Id wiekszym niz minId
   * @returns {Promise<any>}
   *
   * @memberOf UserData
   */
  async getChatMessageForThread(
    threadId: number,
    minDate: string = null,
    minID: number = null
  ): Promise<ChatThreadMessagesData> {
    let token: Token = await this.userAuth.getToken();
    if (!token) {
      log.error("getChatMessageForThread: user is not logged");
      return undefined;
    }

    let workspace = this.appConfig.Config.API.methods.Chat.getThread;
    let conferenceData = await this.getUserStatusForEvent();
    let result, result2: APIResponse;
    try {
      result = await this.apiCall.cacheCall(
        workspace.class,
        workspace.method,
        [conferenceData.event_id, threadId, minDate, minID],
        token.getAccessTokenString(),
        true,
        false
      );
    } catch (error) {
      log.error("getChatMessageForThread: error during API request", error);
      this.goToErrorPage("api");
      return undefined;
    }
    if (result.getContent().unread && minID == null && minDate == null) {
      workspace = this.appConfig.Config.API.methods.Chat.setThreadAsRead;
      try {
        result2 = await this.apiCall.cacheCall(
          workspace.class,
          workspace.method,
          [conferenceData.event_id, threadId],
          token.getAccessTokenString(),
          true,
          false
        );
      } catch (error) {
        log.error("getChatMessageForThread: error during API request - setting thread as read", error);
      }
      log.debug("getChatMessageForThread: set thread as read", result2);
    }
    log.debug(
      "getChatMessageForThread: threadId=" + threadId + ", minId=" + minID + ", minDate=" + minDate + ", result=",
      result
    );
    return <ChatThreadMessagesData>result.getContent();
  }

  /**
   * Wysyła wiadomość komunikatora
   * @param recipientId
   * @param message
   */
  async sendChatMessage(recipientId: number, message: string): Promise<boolean> {
    log.debug("sendChatMessage: send message to " + recipientId + ", message=" + message);
    let token: Token = await this.userAuth.getToken();
    if (!token) {
      log.error("getChatMessageForThread: user is not logged");
      return false;
    }

    let workspace = this.appConfig.Config.API.methods.Chat.sendMessage;
    let conferenceData = await this.getUserStatusForEvent();
    let result, result2: APIResponse;
    try {
      result = await this.apiCall.cacheCall(
        workspace.class,
        workspace.method,
        [conferenceData.event_id, recipientId, message],
        token.getAccessTokenString(),
        true,
        false
      );
    } catch (error) {
      log.error("sendChatMessage: error during API request", error);
      return false;
    }
    return result.isSuccess();
  }

  /**
   *  Przekierowanie do strony bledu
   * @param  {} mode='temp'
   */
  goToErrorPage(mode = "temp", params = {}) {
    log.debug("goToErrorPage: mode=" + mode, params);
    this.navCtrl.navigateRoot("/error/" + mode, { queryParams: params });
  }

  /**
   * Nawiguje do strony komponetnu
   * @param  {string} componentPage
   * @param  {} navDirection="root"
   */
  goToPage(componentPage: string, navDirection = "root") {
    let url: string;
    switch (componentPage) {
      case "home":
        url = this.getUrlPrefix("home") + "home";
      case "login":
        url = this.getUrlPrefix("more") + componentPage;
        break;
      case "chat":
        url = this.getUrlPrefix("chat") + componentPage;
        break;
      default:
        log.warn("goToPage: unhandled component: " + componentPage);
        url = "/" + componentPage;
    }
    log.debug("goToPage: url=" + url + ", direction=" + navDirection);
    switch (navDirection) {
      case "back":
        this.navCtrl.navigateBack(url);
        break;
      case "forward":
        this.navCtrl.navigateForward(url);
        break;
      case "root":
      default:
        this.navCtrl.navigateRoot(url);
    }
  }

  async getModules() {
    const conferenceData = await this.getConferenceConfig();
    const lang = await this.appConfig.getLang();
    const modules = conferenceData.modules[lang];
    let result = [];
    let dModule;
    let isLogged = this.isUserLogged();
    let chatPermission = await this.getChatPermission();
    log.debug("getModules: isLogged=" + isLogged + ", chatPermission=" + chatPermission);
    for (let x = 0; x < modules.length; x++) {
      if (DEFAULT_MODULES[modules[x].name]) {
        dModule = DEFAULT_MODULES[modules[x].name];
        if (dModule.logged === true && !isLogged) continue;
        if (dModule.logged === false && isLogged) continue;
        if (modules[x].name == "chat" && chatPermission === false) continue;
        result.push({
          title: dModule.title,
          link: dModule.link,
          icon: dModule.icon,
          name: modules[x].name
        });
      }
    }
    log.debug("getModules: result", result);
    return result;
  }
}
