import { Injectable } from "@angular/core";
import { NativeAudio } from "@ionic-native/native-audio/ngx";
import { Logger } from "./logger.service";
import { AppConfigService } from "./app-config.service";
import { ProviderWithInit } from "./api/provider-with-init";
import { Platform } from "@ionic/angular";
const log = new Logger("AudioService");

interface Sound {
  key: string;
  asset: string;
}

@Injectable({
  providedIn: "root"
})
export class AudioService extends ProviderWithInit {
  private soundsPreloaded: boolean = false;
  private soundsEnabled = false;
  private audioPlayer: HTMLAudioElement = new Audio();
  private forceWebAudio = false;
  private isNative = false;
  private sounds: Sound[] = [];
  private lastAudioPlayTime;
  /**
   * odstęp w milisekundach między odtwarzaniem dźwięku
   * użytkownikowi
   */
  private audioPlayInterval = 3000;

  constructor(private nativeAudio: NativeAudio, private appConfig: AppConfigService, private platform: Platform) {
    super();
    platform.ready().then(() => {
      if (platform.is("cordova")) {
        this.isNative = true;
      }
    });
  }

  protected async init() {
    log.debug("ready start");
    this.preloadSounds();
    this.listenToEvents();
    super.init();
    log.debug("ready true");
  }

  preload(key: string, asset: string): void {
    if (!this.sounds.filter(sound => sound.key === key).length) {
      if (this.isNative && !this.forceWebAudio) {
        this.platform.ready().then(() => this.nativeAudio.preloadSimple(key, asset));
        this.sounds.push({
          key: key,
          asset: asset
        });
      } else {
        const audio = new Audio();
        audio.src = asset;
        this.sounds.push({
          key: key,
          asset: asset
        });
      }
    }
  }

  async preloadSounds() {
    if (this.soundsPreloaded) return true;
    /*
    if (!this.appConfig.isCordova()) {
      this.soundsEnabled = false;
      return false;
    }*/
    await this.appConfig.ready();
    log.debug('preloadSounds: Sounds', this.sounds);
    const sounds = [
      {
        key: "notify",
        asset: this.appConfig.Config.Sounds.notificationSound
      },
      {
        key: "sendMessage",
        asset: this.appConfig.Config.Sounds.sendMessageSound
      },
      {
        key: "error",
        asset: this.appConfig.Config.Sounds.errorSound
      },
      {
        key: "ok",
        asset: this.appConfig.Config.Sounds.okSound
      },
      {
        key: "tick",
        asset: this.appConfig.Config.Sounds.tickSound
      }
    ];
    for (let x = 0; x < sounds.length; x++) {
      try {
        this.preload(sounds[x].key, sounds[x].asset);
      } catch (error) {
        log.warn("preloadSounds: error", error);
      }
    }

    let settings = await this.appConfig.appSettings.getSettings();
    this.soundsEnabled = settings.playSounds;
    this.soundsPreloaded = true;
    log.debug("preloadSounds: sounds preloaded, enabled=" + this.soundsEnabled, this.sounds);
  }

  listenToEvents() {
    this.appConfig.appEvents.subscribe(this.appConfig.appEvents.event.CHAT_MESSAGE_RECEIVED, () => {
      this.playSound("notify");
    });
    this.appConfig.appEvents.subscribe(this.appConfig.appEvents.event.CHAT_MESSAGE_SENDED, () => {
      this.playSound("sendMessage");
    });
    this.appConfig.appEvents.subscribe(this.appConfig.appEvents.event.USER_LOGIN, () => {
      this.playSound("ok");
    });
    this.appConfig.appEvents.subscribe(this.appConfig.appEvents.event.USER_LOGOUT, () => {
      this.playSound("ok");
    });
    this.appConfig.appEvents.subscribe(this.appConfig.appEvents.event.API_ERROR_500, () => {
      this.playSound("error");
    });
    this.appConfig.appEvents.subscribe(this.appConfig.appEvents.event.NETWORK_DISCONNECTED, () => {
      this.playSound("error");
    });
    this.appConfig.appEvents.subscribe(this.appConfig.appEvents.event.USER_OP_ERROR, () => {
      this.playSound("error");
    });
    this.appConfig.appEvents.subscribe(this.appConfig.appEvents.event.USER_OP_SUCCESS, () => {
      this.playSound("ok");
    });
    this.appConfig.appEvents.subscribe(this.appConfig.appEvents.event.USER_CHANGE_PROFILE, () => {
      this.playSound("ok");
    });
    this.appConfig.appEvents.subscribe(this.appConfig.appEvents.event.USER_SETTINGS_CHANGED, settings => {
      this.soundsEnabled = settings.playSounds;
      this.playSound("ok");
    });
  }

  async playOk(): Promise<any> {
    return await this.playSound("ok");
  }

  async playError(): Promise<any> {
    return await this.playSound("ok");
  }

  async playTick(): Promise<any> {
    return await this.playSound("tick");
  }

  /**
   * Odtwarza wcześniej załadowany dźwięk
   *
   * @param {string} sound
   *
   * @memberOf Audio
   */
  async playSound(sound: string): Promise<any> {
    log.debug("[Audio] playSound " + sound);
    if (!this.soundsEnabled) {
      log.debug("[Audio] Sounds disabled, exiting");
      return false;
    }
    let now = new Date();
    if (!this.lastAudioPlayTime || now.getTime() - this.lastAudioPlayTime.getTime() > this.audioPlayInterval) {
      log.debug("[Audio] playing sound " + sound);
      this.lastAudioPlayTime = new Date();
      if (this.appConfig.isCordova) return this.play(sound);
      else return false;
    } else {
      log.warn(
        "[Audio] Sounds disabled cause too short time from last sound",
        now.getTime() - this.lastAudioPlayTime.getTime()
      );
      return false;
    }
  }

  private play(key: string): void {
    const soundToPlay: Sound = this.sounds.find(sound => sound.key === key);
    if (soundToPlay) {
      if (this.isNative) {
        this.platform
          .ready()
          .then(() =>
            this.nativeAudio
              .play(soundToPlay.key)
              .then(res => log.debug('play:',res), err => log.error("play: error", JSON.stringify(soundToPlay), err))
          );
      } else {
        this.audioPlayer.src = soundToPlay.asset;
        this.audioPlayer.play().catch(() => {}); // ignore web player errors
      }
    }
  }
}
