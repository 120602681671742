import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppConfigService } from '../core/app-config.service';
import { ApiService } from '../core/api/api.service';
import { UserAuthService } from '../core/api/user-auth.service';
import { ClientAuthService } from '../core/api/client-auth.service';
import { ApiCallService} from '../core/api/api-call.service';
import { AppEventsService } from '../core/app-events-service';
import { AppSettingsService } from '../core/app-settings.service';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { LoaderService } from '../core/loader.service';
import { StorageService } from '../core/storage.service';
import { MultimediaService } from '../core/multimedia.service';
import { RouterStateService } from '../core/router-state.service';
import { AudioService } from '../core/audio.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class SharedModule { 
    static forRoot(): ModuleWithProviders {
        return {
          ngModule: SharedModule,
          providers: [
            AppConfigService,            
            AppEventsService,
            AudioService,
            UserAuthService,
            ClientAuthService,
            ApiCallService,
            AppSettingsService,
            ApiService,
            LoaderService,
            StorageService,
            MultimediaService   ,
            RouterStateService         
          ]
        }

    }
}
