import { Injectable } from '@angular/core';
import { SecureStorage, SecureStorageObject } from '@ionic-native/secure-storage/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Storage } from '@ionic/storage';
import { ProviderWithInit } from './api/provider-with-init';
import { Logger } from './logger.service';
import { Platform } from '@ionic/angular';
import { File } from '@ionic-native/file/ngx';
const log = new Logger('StorageService');
@Injectable({
  providedIn: 'root'
})
export class StorageService extends ProviderWithInit {

  readonly STORAGE_LOCAL = 'local';
  readonly STORAGE_NATIVE_SECURE = 'secure';
  readonly STORAGE_NATIVE = 'native';
  readonly STORAGE_DISABLED = 'disabled';
  readonly STORAGE_FILES = 'files'

  /* TYMCZASOWE ROZWIAZANIE DLA ZWISOW NA 11.2 */
  private forceStorageOption; //= this.STORAGE_LOCAL;


  private availableStorages: Array<string> = [];
  private secureStorageObjects: Array<SecureStorageObject> = [];
  private appDir: string = 'emobido';
  private appDirPath: string;

  constructor(
    private secureStorage: SecureStorage,
    private storage: Storage,
    private nativeStorage: NativeStorage,
    private platform: Platform,
    private file: File
  ) {
    super();
    this.JSONStringifyPatch();
  }


  protected async init() {
    log.debug('ready: start - wait for platform ready');
    await this.platform.ready();
    log.debug('ready:  platform ready');
    this.appDirPath = this.file.cacheDirectory;
    if (this.platform.is('cordova')) {
      this.availableStorages.push(this.STORAGE_NATIVE_SECURE);
      this.availableStorages.push(this.STORAGE_NATIVE);
      this.availableStorages.push(this.STORAGE_FILES);
    }
    this.availableStorages.push(this.STORAGE_LOCAL);
    this.availableStorages.push(this.STORAGE_DISABLED);
    
    log.debug('ready: available storages = ', this.availableStorages);
    try {
      await this.checkAppDir();
    } catch (error) {
      log.warn('ready: directory NOT exists, creating', this.appDirPath + '/' + this.appDir);
      try {
        await this.file.createDir(this.appDirPath, this.appDir, false);
      } catch (error) {
        log.error('ready: Error during create app directory: ', error);
      }
      try {
        await this.checkAppDir();
      } catch (error) {
        log.error('ready: Error during checking after create app directory: ', error);
      }
    }

    log.info('ready: true');
    this._readyResolve(true);
  }


  /**
   * Sprawdza czy istnieje katalog aplikacji do przechowywania danych
   */
  private async checkAppDir() {
    await this.file.checkDir(this.appDirPath, this.appDir);
    log.debug('checkAppDir: directory exists', this.appDirPath + '/' + this.appDir);
  }

	/**
	   * Sprawdza czy podany typ storage'u jest dostępny
	 * @param  {string} storageType
	 * @returns string
	 */
  private async getAvailableStorage(preferredStorageType: string = undefined): Promise<string> {
    if (this.forceStorageOption)
      return this.forceStorageOption;
    await this.ready();
    if (!preferredStorageType)
      if (this.availableStorages.indexOf(this.STORAGE_NATIVE) !== -1)
        return this.STORAGE_NATIVE;
    if (this.availableStorages.indexOf(preferredStorageType) !== -1)
      return preferredStorageType;
    return this.STORAGE_LOCAL;
  }

  /**
	 * Czysci storage ze wszystkich danych
	 * 
	 * @param  {string} storageName
	 * @param  {string=''} storageType
	 * @returns Promise
	 */
  async storageClear(storageName: string, storageType: string = ''): Promise<any> {
    await this.ready();
    let realStorageType = await this.getAvailableStorage(storageType);
    log.debug('storageClear: [' + realStorageType + '] read: ' + storageName);
    switch (realStorageType) {
      case this.STORAGE_NATIVE: {
        return this.nativeStorage.clear();
      }
      case this.STORAGE_NATIVE_SECURE:
        {
          let storage = this.secureStorageObjects[storageName];
          if (!storage)
            return false;
          return storage.clear();
        }
      case this.STORAGE_DISABLED: {
        return true;
      }
      case this.STORAGE_LOCAL: {
        await this.storage.ready();
        let keys = await this.storage.keys();
        for (let i = 0; i < keys.length; ++i) {
          if (keys[i].startsWith('api-calls.'))
            log.debug('storageClear: type=local, remove key=', keys[i]);
          await this.storage.remove(keys[i]);
        }
        return true;
      }
      case this.STORAGE_FILES: {
        try {
          await this.file.removeRecursively(this.appDirPath, this.appDir);
        } catch (error) {
          log.error('storageClear: [' + realStorageType + '] error', error);
        }
      }
      default: {
        log.warn('storageClear: [' + realStorageType + '] unsupported type');
        return undefined;
      }
    }
  }

  /**
	 * Wczytuje zmienną ze storage'u
	 *
	 * @param  {string} storageName Nazwa magazynu 
	 * @param  {string} storageKey Nazwa klucza
	 * @param  {string='local'} storageType Typ magazynu 'local' lub 'securestorage'
	 * @returns Promise
	 */
  async storageGet(storageName: string, storageKey: string, storageType: string = ''): Promise<any> {           
    await this.ready();
    let realStorageType = await this.getAvailableStorage(storageType);
    log.debug('storageGet: [' + realStorageType + '] read: ' + storageName + ':' + storageKey);
    let res: string;
    let value;
    switch (realStorageType) {
      case this.STORAGE_NATIVE: {
        try {
          //   return undefined;
          res = await this.nativeStorage.getItem(storageName + storageKey);
        } catch (error) {
          //ITEM_NOT_FOUND
          if (error.code == 2) {
            log.debug('storageGet: [' + realStorageType + '] item not found', storageName + storageKey);
            return undefined;
          }
          log.error('storageGet: [' + realStorageType + '] error', error);
          return undefined;
        }
        break;

      }
      case this.STORAGE_DISABLED: {
        return undefined;
      }
      case this.STORAGE_NATIVE_SECURE:
        {
          let storage = this.secureStorageObjects[storageName];
          if (!storage)
            return undefined;
          res = await storage.get(storageKey);
          break;
        }
      case this.STORAGE_LOCAL:
        {
          await this.storage.ready();
          res = await this.storage.get(storageName + storageKey);
          break;
        }
      case this.STORAGE_FILES:
      let path = this.appDirPath;
      let file = this.appDir + '/' + storageName + storageKey + '.cache';
        try {
          log.debug('storageGet: [' + realStorageType + '] filename=', file);
          let exists = await this.file.checkFile(path, file)
          if (!exists){
            log.debug('storageGet: [' + realStorageType + '] file not exists',  file);
            return undefined;
          }
          log.debug('storageGet: [' + realStorageType + '] file exists',  file);
          res = await this.file.readAsText (path, file);
        } catch (error) {
          if (error.code == 1)
            log.debug('storageGet: [' + realStorageType + '] item not found',  file);
          else
            log.error('storageGet: error', error,  file);
          return undefined;
        } finally{
         
        }

        break;
      default: {
        log.warn('storageGet: [' + realStorageType + '] unsupported type');
        return undefined;
      }
    }
    if (res === undefined || res === null || !res.length) {
      log.warn('storageGet: [' + realStorageType + '] return undefined');
      return res;
    }
    let valueSize: string = (res.length > 1024) ? Math.round(res.length / 1024) + 'kB' : res.length + 'B';
    try {
      value = JSON.parse(res);
    } catch (error) {
      log.error('storageGet: [' + realStorageType + '] JSON decode error', error, res);
      return undefined;
    }

    log.debug('storageGet: [' + realStorageType + '] item size:' + valueSize);
    return value;
  }

  /**
   * @see https://stackoverflow.com/questions/16686687/json-stringify-and-u2028-u2029-check
   */
  JSONStringifyPatch(){
    if (JSON.stringify(["\u2028\u2029"]) === '["\u2028\u2029"]') JSON.stringify = function (a) {
      var b = /\u2028/g,
          c = /\u2029/g;
      return function (d, e, f) {
          var g = a.call(this, d, e, f);
          if (g) {
              if (-1 < g.indexOf('\u2028')) g = g.replace(b, '\\u2028');
              if (-1 < g.indexOf('\u2029')) g = g.replace(c, '\\u2029');
          }
          return g;
      };
  }(JSON.stringify);
  }

	/**
	 * Zapisuje dane do magazynu
	 * 
	 * @param  {string} storageName Nazwa magazynu
	 * @param  {string} storageKey Klucz
	 * @param  {any} value Dane do zapisania
	 * @param  {string='local'} storageType Typ magazynu ('local' lub 'secure')
   *
	 * @returns Promise
	 */
  async storageSet(storageName: string, storageKey: string, valueData: any, storageType: string = ''): Promise<any> {        
    await this.ready();
    let realStorageType = await this.getAvailableStorage(storageType);

    let value: string = JSON.stringify(valueData);
    let valueSize: string;
    if (!value)
      valueSize = '0 (overwrite)'; else
      valueSize = (value.length > 1024) ? Math.round(value.length / 1024) + 'kB' : value.length + 'B';
    log.debug('storageSet: [' + realStorageType + '] save: ' + storageName + ':' + storageKey + ', size: ' + valueSize);

    switch (realStorageType) {
      case this.STORAGE_NATIVE: {
        try {
          await this.nativeStorage.setItem(storageName + storageKey, value);
          return true;
        } catch (error) {
          log.debug('storageSet: [' + realStorageType + '] error', error);
          return undefined;
        }

      }
      case this.STORAGE_DISABLED: {
        return undefined;
      }
      case this.STORAGE_NATIVE_SECURE:
        {
          let storage = this.secureStorageObjects[storageName];
          if (!storage)
            storage = await this.secureStorage.create(storageName);
          return await storage.set(storageKey, value);
        }
      case this.STORAGE_LOCAL: {
        await this.storage.ready();
        return await this.storage.set(storageName + storageKey, value);
      }
      case this.STORAGE_FILES:
        try {          
          let filename = storageName + storageKey + '.cache';
          log.debug('storageSet: [files] filename=', filename, this.appDirPath);          
          let result = await this.file.writeFile(this.appDirPath + this.appDir, filename, value, {replace:true, append:false});
          log.debug('storageSet: [files] save result =', result);     
          return true;
            
        } catch (error) {
          log.error('storageSet: [files] error', error);
          return undefined;
        }
      default: {
        log.warn('storageGet: [' + realStorageType + '] unsupported type');
        return undefined;
      }
    }
  }
}


