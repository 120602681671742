import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { GalleryViewerComponent} from './gallery-viewer.component';
import { MomentModule } from 'ngx-moment';
@NgModule({
     declarations: [
       GalleryViewerComponent
     ],
     imports: [
       IonicModule,
       CommonModule,
       FormsModule,
       MomentModule,
       TranslateModule.forChild()
     ],
     entryComponents: [
       GalleryViewerComponent
     ]
})
export class GalleryViewerComponentModule {}