import * as API_METHODS_CONFIG from "../app/core/defaults/api-methods";
import { SOUNDS_CONFIG } from "../app/core/defaults/sounds";
export let CONFIG = {
  Application: {
    Id: 14,
    Multi: false,
    TabsEnabled: false
  },
  Languages: {
    Available: {
      pl: 1,
      en: 2
    }
  },
  API: {
    URL: "https://api.ptwp.pl/auth/konto.ptwp.pl/v1/",
    URLFB: "https://api.ptwp.pl/auth/facebook/app.php",
    URLLinkedIn: "https://api.ptwp.pl/auth/linkedin/app.php",
    ClientId: "MOBI_TEST_PTWP",
    ClientSecret: "k38syjbapr67",
    PublicClientId: "MOBI_TEST_PTWP_PUBLIC_API",
    PublicClientSecret: "3212-98-984-109",
    methods: API_METHODS_CONFIG.API_METHODS
  },
  OneSignal: {
    enabled: true,
    appID: "4bf2e535-5474-4ae3-a55e-20c19e781ca6",
    android: "3187812817"
  },
  Google: {
    mapsKey: "AIzaSyCsL7pKeK_XdJmdh-FxnDgUlHJQOx4rKNg"
  },
  DefaultUserSettings: {
    lang: "pl",
    storeLoginCredentials: true,
    loginType: "standard",
    playSounds: false
  },
  Sounds: SOUNDS_CONFIG.files,
  Tabs:{
    pl: [
    {tab: "home", name: "Info", icon: "information-circle", active: false},
    {tab: "schedule", name: "Schedule", icon: "calendar", active: true},
    {tab: "speakers", name: "Speakers", icon: "contacts", active: false},
    {tab: "news", name: "News", icon: "paper", active: false}
  ],
    en:[
      {tab: "home", name: "Info", icon: "information-circle", active: false},
      {tab: "schedule", name: "Schedule", icon: "calendar", active: true},
      {tab: "speakers", name: "Speakers", icon: "contacts", active: false},
      {tab: "news", name: "News", icon: "paper", active: false}
    ]
  }
};
