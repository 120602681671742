import { Component, OnInit, Input } from '@angular/core';
import { Logger } from "../../core/logger.service";
import { ModalController } from '@ionic/angular';
const log = new Logger("ImageViewer");
@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {
  @Input() src = '';
  @Input() title? = '';
  @Input() description? = '';
  @Input() slideOpts? = {
    centeredSlides: 'true'
  };

  constructor(private modalController: ModalController) { }

  closeViewer(){
    log.debug('dismiss: ');
    this.modalController.dismiss();
  }

  ngOnInit() {
   
  }

  
}