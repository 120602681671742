import * as Moment from 'moment';
import 'moment/min/locales';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as APPCONFIG from "../../config/app-config";
import { AppEventsService } from "./app-events-service";
import { AppSettingsService } from "./app-settings.service";
import { environment } from '../../environments/environment';
import { Logger } from './logger.service';
import { Platform } from '@ionic/angular';
import { Network } from '@ionic-native/network/ngx';
import { ProviderWithInit } from './api/provider-with-init';
const log = new Logger('AppConfigService');
@Injectable({
  providedIn: 'root'
})
export class AppConfigService extends ProviderWithInit {

  private isPlatformCordova: boolean;
  private forceOffline: boolean = false;
  private isReady: boolean = false;
  private translatorInitialized: boolean = false;

  readonly HIDE_TAB_BAR = 'HIDE_TAB_BAR';
  
  /* static configuration array */
  public Config: any;
  private appId: number;
  constructor(
    public translator: TranslateService,
    public appEvents: AppEventsService,
    public appSettings: AppSettingsService,
    public platform: Platform,
    public network: Network
  ) {
    super();
    if (environment.production) {
      Logger.enableProductionMode();
    }
    log.debug('constructor');
    this.setConfig();
  }

  protected async init() {
    log.debug('ready: start');
    let platform = await this.platform.ready();
    log.info('ready: plaftorm.ready() fired, platform = ' + platform);
    this.isPlatformCordova = this.platform.is('cordova');
    this.isOnline();
    await this.translatorInit();
    log.info('ready: true');
    super.init();
  }

  /**
   * Czy platforma Cordova
   * 
   * @returns {boolean}
   */
  async isCordova(): Promise<boolean> {
    log.debug('isCordova start', this.isPlatformCordova);
    await this.ready();
    log.debug('isCordova end', this.isPlatformCordova);
    return this.isPlatformCordova;
  }
  /**
   * @returns boolean
   */
  isInfoKioskMode(): boolean {
    return this.Config.Application.InfoKiosk;
  }

  protected setConfig() {
    this.Config = APPCONFIG.CONFIG;
    log.debug('setConfig: ', this.Config);
    this.appEvents.publish(this.appEvents.event.APP_CONFIG_LOADED, APPCONFIG.CONFIG);
    return true;
  }

  /**
	 * Zwraca informację, czy jest połączenie sieciowe
	 * 
	 * @returns 
	 * 
	 * @memberOf AppConfig
	 */
  public async isOnline(): Promise<boolean> {
    await this.ready();
    if (this.forceOffline) return false;
    if (!this.platform.is('cordova'))
      return true; //tu nie jesteśmy w stanie określić

    log.debug('isOnline:', this.network.type);
    if (this.network.type === 'none')
      return false;
    return true;
  }


  /**
   * Ustawia nowy język
   * 
   * @param  {string} newLang
   */
  async setLang(newLang: string) {
    await this.ready();
    await this.appSettings.ready();
    /* zmiana języka */
    if (newLang !== await this.getLang()) {
      log.info("setLang newLang=" + newLang);
      await this.translator.use(newLang).toPromise();
      Moment.locale(newLang);
      await this.appSettings.setLang(newLang);
      this.appEvents.publish(this.appEvents.event.CHANGE_LANGUAGE, newLang);
    } else {
      //wracamy na stronę główną      
      this.appEvents.publish(this.appEvents.event.NAVIGATION_SET_PAGE, "/home");
    }
  }

  /**
   * Aktualny język
   * 
   * @returns {string}
   */
  async getLang(): Promise<string> {
    await this.ready();
    let appSettings = await this.appSettings.getSettings();
    log.debug('getLang()=' + appSettings.lang);
    if (!appSettings.lang)
      return this.Config.DefaultUserSettings.lang;
    return appSettings.lang;
  }

  /* inicjacja translatora */
  private async translatorInit(): Promise<boolean> {
    if (this.translatorInitialized)
      return this.translatorInitialized;
    log.debug('translatorInit: start');
    const appSettings = await this.appSettings.getSettings();
    log.debug('translatorInit lang:' + appSettings.lang);
    this.translator.use(appSettings.lang).toPromise();
    log.debug('translatorInit setting Momen locale');
    Moment.locale(appSettings.lang);
    this.translatorInitialized = true;
    this.appEvents.publish(this.appEvents.event.TRANSLATION_INIT);
    log.info('translatorInit: ' + this.translatorInitialized);
    return this.translatorInitialized;
  }



  async setAppId(newId: number) {
    await this.appSettings.storage.storageSet('app', 'appId', newId);
    this.Config.Application.Id = newId;
    this.appId = newId;
    this.appEvents.publish(this.appEvents.event.APPLICATION_EVENT_CHANGED, newId);
  }

  /**
   * Zwraca ID biezacej aplikacji
   * @returns number
   */
  async getAppId(): Promise<number> {
    if (!this.Config.Application.Multi){
      log.debug('getAppId: multiEvents disabled');
      this.appId = this.Config.Application.Id;
      return this.appId;
    }  
    if (this.appId){
      log.debug('getAppId: <read from memory>' + this.appId);
      return this.appId;
    }
    await this.appSettings.ready();
    let appId = await this.appSettings.storage.storageGet('app', 'appId');
    if (appId) {
      this.appId = appId;
      log.debug('getAppId: <read from storage>' + this.appId);
      return appId;
    }
    if (this.Config.Application.Id) {
      this.appId = this.Config.Application.Id;
      log.debug('getAppId: <read from config>' + this.appId);
      return this.Config.Application.Id;
    }
    log.debug('getAppId: undefined');
    return undefined;
  }

}
