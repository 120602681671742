import { Injectable } from '@angular/core';
import { AppSettingsData } from './interfaces/app-settings-data';
import { Logger } from './logger.service';
import { Platform } from '@ionic/angular';
import { AppEventsService } from './app-events-service';
import * as APPCONFIG from "../../config/app-config";
import { ProviderWithInit } from './api/provider-with-init';
import { StorageService } from './storage.service';
const log = new Logger('AppSettingsService');
@Injectable({
	providedIn: 'root'
})
export class AppSettingsService extends ProviderWithInit {



	private settings: AppSettingsData;
	private storageName = 'userSettings';
	private storageKey = 'settings';
	private settingsLoaded = false;


	constructor(
		private platform: Platform,
		private appEvents: AppEventsService,
		public storage: StorageService
	) {
		super();
		log.debug('constructor');

	}

	/**
		*  Ustawia domylne wartości
		*/
	private setDefaults() {
		this.settings = <AppSettingsData>APPCONFIG.CONFIG.DefaultUserSettings;
		log.debug('setDefaultSettings:', this.settings);
	}

	protected async init() {
		log.debug('ready: start');
		this.setDefaults();
		await this.platform.ready();
		await this.readSettings();
		log.info('ready: true');
		super.init();
	}

	/**
 	* Wczytuje dane z magazyny (lokalnego lub secure storage) lub z pamięci - cache'u
    * 
	* @returns Promise<AppSettingsData>
    */
	async getSettings(): Promise<AppSettingsData> {
		await this.ready();
		if (!this.settingsLoaded) 
			await this.readSettings();
		return this.settings;
	}
	/**
	 * Wczytuje dane z magazyny (lokalnego lub secure storage) lub z pamięci - cache'u
	   * 
	 * @returns Promise<AppSettingsData>
	   */
	private async readSettings(): Promise<AppSettingsData> {
		if (!this.settingsLoaded) {
			try {
				let settings = await this.storage.storageGet(this.storageName, this.storageKey);
				if (!settings) {
					log.debug('getSettings: load defaults, no stored settings');
					this.setDefaults();
					this.saveSettings(this.settings);
					this.settingsLoaded = true;
					this.appEvents.publish(this.appEvents.event.USER_SETTINGS_LOADED, this.settings);
					return this.settings;
				}
				try {
					this.settings = settings;
					this.settingsLoaded = true;
					if (!this.settings.lang)
						this.settings.lang = APPCONFIG.CONFIG.DefaultUserSettings.lang;
					log.debug('getSettings: settings loaded from storage: ', this.settings);
					this.appEvents.publish(this.appEvents.event.USER_SETTINGS_LOADED, this.settings);
					return this.settings;
				} catch (error) {
					log.error("getSettings: error: loaded from storage, but data has wrong format", error);
					this.setDefaults();
					this.saveSettings(this.settings);
					this.settingsLoaded = true;
					this.appEvents.publish(this.appEvents.event.USER_SETTINGS_LOADED, this.settings);
					return this.settings;
				}
			} catch (error) {
				this.setDefaults();
				log.error('getSettings: error during data read, set to defaults: ', error);
				this.saveSettings(this.settings);
				this.settingsLoaded = true;
				this.appEvents.publish(this.appEvents.event.USER_SETTINGS_LOADED, this.settings);
				return this.settings;
			}
		}
		else {
			log.debug('getSettings: read from memory cache: ', this.settings);
			return this.settings;
		}
	}

	/**
	 * Zapisuje dane aplikacji
	 * 
	   * @param  {AppSettingsData} settings Dane do zapisania
	   * @returns Promise
	   */
	async saveSettings(settings: AppSettingsData): Promise<any> {
		this.settings = settings;
		log.debug('saveSettings: ', this.settings);
		await this.storage.storageSet(this.storageName, this.storageKey, this.settings);
		this.appEvents.publish(this.appEvents.event.USER_SETTINGS_CHANGED, this.settings);
	}

	/**
	 * Ustawia język i zapisuje go magazynie danych
	   * 
	   * @param  {string} lang Kod języka
	   * @returns Promise
	   */
	async setLang(lang: string): Promise<any> {
		return this.getSettings().then(
			(settings) => {
				this.settings.lang = lang;
				return this.saveSettings(this.settings);
			});
	}

	/**
	 * Przywraca i zapisuje domyślne ustawienia
	   * 
	   * @returns Promise
	   */
	async clean(): Promise<any> {
		this.setDefaults();
		log.info('clean: Restore default settings: ', APPCONFIG.CONFIG.DefaultUserSettings);
		return this.saveSettings(this.settings);
	}

	/**
	 * Czy załadowano ustawienia
	   * 
	   * @returns boolean
	   */
	public isSettingsLoaded(): boolean {
		return this.settingsLoaded;
	}

}
