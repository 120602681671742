import { LoadingController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { Logger } from './logger.service';
const log = new Logger('LoaderService');
@Injectable({
  providedIn: 'root'
})
export class LoaderService {

    enabled = true;
    loader: any;
    private visible = false;
    constructor( 
        private loadingCtrl: LoadingController,
        private appConfig: AppConfigService) {
    }

    async show( content: string = 'loader.loadingData' ): Promise<any> {
        log.debug('show: start');
        await this.appConfig.ready();
        if (!this.enabled)
            return;
        log.debug('show: visible:', this.visible);

        if ( this.visible ) {
            this.dismiss();
            return;
        }
        let data:string = await this.appConfig.translator.get( content ).toPromise();
        log.debug('show message='+data);
        this.loader =  await this.loadingCtrl.create( {
                    message: data
                });
         this.visible = true;
         return await this.loader.present();
    }

    isVisible() {
        return this.visible;
    }

    async dismiss() { 
     if (!this.enabled)
            return;

        log.debug('dismiss: visible:', this.visible);
        if (this.visible)
            {
                await this.loader.dismiss()
                .catch((reason: any) => {
                    log.error('error:', reason);
                    this.loader.dismissAll();  
                });
            }
        this.visible = false; 
    }
}
