import { Injectable } from '@angular/core';
import { resolve } from 'q';
@Injectable({
    providedIn: 'root'
})
export class ProviderWithInit {
    protected _readyPromise: Promise<boolean>;
    protected _readyResolve: (value: boolean) => void;
    protected _isReadyRunning = false;

    constructor() {

    }

    ready(): Promise<boolean> {
        if (!this._isReadyRunning) {
            this._readyPromise = new Promise(res => { this._readyResolve = res; });
            this._isReadyRunning = true;
            this.init();
        }
        return this._readyPromise;
    }
	/**
     * Inicjalizacja do nadpisania w klasach potomych
	 */
    protected async init() {
        this._readyResolve(true);
    }
}