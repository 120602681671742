import { Injectable } from '@angular/core';
import { Logger } from './logger.service';
import { StreamingMedia, StreamingVideoOptions } from '@ionic-native/streaming-media/ngx';
import { YoutubeVideoPlayer } from '@ionic-native/youtube-video-player/ngx';
const log = new Logger('MultimediaService');

@Injectable({
  providedIn: 'root'
})
export class MultimediaService {

  constructor(
    private streamingMedia: StreamingMedia,
    private youtube: YoutubeVideoPlayer
  ) { }

  playMovie(movieSrc: string){
    let options: StreamingVideoOptions = {
      successCallback: () => { console.log('playMovie: Video played') },
      errorCallback: (e) => { console.log('playMovie: Error streaming') },
      orientation: 'landscape',
      shouldAutoClose: true,
      controls: false
    };
    log.debug('playMovie: movie='+movieSrc +', options=', options);
    this.streamingMedia.playVideo(movieSrc, options);
  }  

  playYoutubeMovie(moviesrc: string){
    log.debug('playYoutubeMovie src='+moviesrc);
    this.youtube.openVideo(moviesrc);
  }
}
