export const API_METHODS = {
    Application: {
        getConfig: {
            class: '.PTWP.API.MobiApp.App',
            method: 'getConfiguration'
        },
        listApps: {
            class: '.PTWP.API.MobiApp.App',
            method: 'listApps'
        }
    },
    News: {
        listItems: {
            class: '.PTWP.API.MobiApp.News',
            method: 'listItems'
        },
        getItem: {
            class: '.PTWP.API.MobiApp.News',
            method: 'getDetail'
        }
    },
    Galleries: {
        listItems: {
            class: '.PTWP.API.MobiApp.Galleries',
            method: 'listItems'
        },
        getItem: {
            class: '.PTWP.API.MobiApp.Galleries',
            method: 'getDetail'
        }
    },
    Movies: {
        listItems: {
            class: '.PTWP.API.MobiApp.Movies',
            method: 'listItems'
        },
        getItem: {
            class: '.PTWP.API.MobiApp.Movies',
            method: 'getDetail'
        }
    },
    Social: {
        listItemsYoutube: {
            class: '.PTWP.API.MobiApp.Social',
            method: 'listYoutubeItems'
        },
        listItemsTwitter: {
            class: '.PTWP.API.MobiApp.Social',
            method: 'listTwitterItems'
        },
        listItemsFacebook: {
            class: '.PTWP.API.MobiApp.Social',
            method: 'listFacebookItems'
        }						
    },					
    Partners: {
        listItems: {
            class: '.PTWP.API.Conference.Partners',
            method: 'listPartnersHTML'
        }
    },
    Sessions: {
        listItems: {
            class: '.PTWP.API.Conference.Agenda',
            method: 'listAgenda'
        }
    },
    User: {
        getUserDetail: {
            class: '.PTWP.API.Account.User',
            method: 'getUserProfileSimple'
        },
        getUserStatusForEvent: {
            class: '.PTWP.API.Account.Conference',
            method: 'getUserStatusForConference'
        },
        setUserProfile: {
            class: '.PTWP.API.Account.User',
            method: 'setUserProfile'
        },
        setUserProfileImage: {
            class: '.PTWP.API.Account.User',
            method: 'setUserProfileImage'
        },
        createUserAccount: {
            class: '.PTWP.API.Account.User',
            method: 'createUserAccount'
        },
        conferenceRegister: {
            class: '.PTWP.API.Account.Conference',
            method: 'conferenceRegister'
        },
    },
    Chat: {
        checkAccess: {
            class: '.PTWP.API.Conference.Communicator',
            method: 'userCommunicatorAccess'
        },
        userSearch: {
            class: '.PTWP.API.Conference.Communicator',
            method: 'userSearch'
        },
        listUsers: {
            class: '.PTWP.API.Conference.Communicator',
            method: 'getAllUsersWithDict'
        },
        getMessages: {
            class: '.PTWP.API.Conference.Communicator',
            method: 'getMessagesAsThreads'
        },
        getMessagesAdv:{
            class: '.PTWP.API.Conference.Communicator',
            method: 'getMessages'
        },
        getThread: {
            class: '.PTWP.API.Conference.Communicator',
            method: 'getMessagesAsThreadsForRecipient'
        },
        sendMessage: {
            class: '.PTWP.API.Conference.Communicator',
            method: 'sendMessage'
        },
        setThreadAsRead: {
            class: '.PTWP.API.Conference.Communicator',
            method: 'setThreadAsRead'
        }
    }

}