export let DEFAULT_MODULES =
{
    'info':
    {
        icon: 'information-circle',
        title: 'TabsAbout',
        link: '/info',
        tabBar: true,
        logged: null
    },
    'login':
    {
        icon: 'person',
        title: 'Login',
        link: '/login',
        tabBar: false,
        logged: false
    },
    'account':
    {
        icon: 'person',
        title: 'Account',
        link: '/account',
        tabBar: false,
        logged: true
    },
    'schedule':
    {
        icon: 'calendar',
        title: 'Schedule',
        link: '/sessions',
        tabBar: true,
        logged: null
    },
    'speakers':
    {
        icon: 'contacts',
        title: 'Speakers',
        link: '/speakers',
        tabBar: true,
        logged: null
    },
    'chat':
    {
        icon: 'chatboxes',
        title: 'Chat',
        link: '/chat',
        tabBar: true,
        logged: true
    },
    'news':
    {
        icon: 'paper',
        title: 'News',
        link: '/news',
        tabBar: true,
        logged: null
    },
    'social':
    {
        icon: 'share',
        title: 'Social',
        link: '/social',
        tabBar: true,
        logged: null
    },
    'partners':
    {
        icon: 'flower',
        title: 'Partners',
        link: '/partners',
        tabBar: false,
        logged: null
    },
    'settings':
    {
        icon: 'settings',
        title: 'Settings',
        link: '/settings',
        tabBar: false,
        logged: null
    },
    'logout':
    {
        icon: 'log-out',
        title: 'Logout',
        link: '/login/1',
        tabBar: true,
        logged: true,
    },
    'language':
    {
        icon: 'phone-portrait',
        title: 'ChangeLanguage',
        link: '/language-change',
        tabBar: false,
        logged: null
    },
    'refresh':
    {
        icon: 'refresh',
        title: 'Refresh',
        link: '/refresh',
        tabBar: false,
        logged: null
    }
}      