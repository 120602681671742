import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
//  { path: '', loadChildren: './pages/tabs/tabs.module#TabsPageModule' },
  /*
//wersja z tabbarem
  { path: '', loadChildren: './pages/tabs/tabs.module#TabsPageModule'},   
  
  { path: "home", redirectTo:"", pathMatch:"full"},
  { path: "login", redirectTo:"/tabs/login", pathMatch:"full"},
  { path: "account", redirectTo:"/tabs/account", pathMatch:"full"},
  { path: 'error/:mode', redirectTo:"/tabs/error/:mode", pathMatch:"full"},
  {
    path: "conference-select",
    loadChildren: "./pages/conference-select/conference-select.module#ConferenceSelectPageModule"
  },
  { path: "**", redirectTo: "/tabs/error/404" }
  */
 /* */
  //wersja z menu
  { path: '', loadChildren: './pages/home/home.module#HomePageModule' },
  
  { path: 'static/:menu/:name/:index', loadChildren: './pages/static-page/static-page.module#StaticPagePageModule' },
  { path: 'home/static/:menu/:name/:index', loadChildren: './pages/static-page/static-page.module#StaticPagePageModule' },
  { path: 'home', redirectTo: "/home/home" },  
  { path: 'home/home', loadChildren: './pages/home/home.module#HomePageModule' },

  { path: 'logout', redirectTo: 'login/logout=true' },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },  
  { path: 'login/:logout', loadChildren: './pages/login/login.module#LoginPageModule' },  
  { path: 'error', loadChildren: './pages/error/error.module#ErrorPageModule' },
  { path: 'account', loadChildren: './pages/account/account.module#AccountPageModule' },
  { path: 'language-change', loadChildren: './pages/language-change/language-change.module#LanguageChangePageModule' },
  
  
  { path: 'chat/chat-person/:id', loadChildren: './pages/chat-person/chat-person.module#ChatPersonPageModule' },
  { path: 'chat/chat-users', loadChildren: './pages/chat-users/chat-users.module#ChatUsersPageModule' },
  { path: 'chat', redirectTo: "/chat/chat" },  
  { path: 'chat/chat', loadChildren: './pages/chat-threads/chat-threads.module#ChatThreadsPageModule' },
  
  { path: 'news/galleries-detail/:id', loadChildren: './pages/galleries-detail/galleries-detail.module#GalleriesDetailPageModule' },
  { path: 'galleries', redirectTo: "/news/galleries" },
  { path: 'news/galleries', loadChildren: './pages/galleries/galleries.module#GalleriesPageModule' },
  
  
  { path: 'news/movies-detail/:id', loadChildren: './pages/movies-detail/movies-detail.module#MoviesDetailPageModule' },
  { path: 'movies', redirectTo: "/news/movies" },
  { path: 'news/movies', loadChildren: './pages/movies/movies.module#MoviesPageModule' },
  

  { path: 'news/news-detail/:id', loadChildren: './pages/news-detail/news-detail.module#NewsDetailPageModule' },
  { path: 'news', redirectTo: "/news/news" },
  { path: 'news/news', loadChildren: './pages/news/news.module#NewsPageModule' },
  

  { path: 'partners', loadChildren: './pages/partners/partners.module#PartnersPageModule' },
  { path: 'polls', loadChildren: './pages/polls/polls.module#PollsPageModule' },
  { path: 'refresh', loadChildren: './pages/refresh/refresh.module#RefreshPageModule' },

  { path: 'schedule/sessions-detail/:id', loadChildren: './pages/sessions-detail/sessions-detail.module#SessionsDetailPageModule' },
  { path: 'schedule/sessions', loadChildren: './pages/sessions/sessions.module#SessionsPageModule' },
  { path: 'sessions', redirectTo: "/schedule/sessions" },
  
  { path: 'settings', loadChildren: './pages/settings/settings.module#SettingsPageModule' },
  
  { path: 'social/social/:type', loadChildren: './pages/social/social.module#SocialPageModule' },
  { path: 'social', redirectTo: "/social/social" },
  { path: 'social/social', loadChildren: './pages/social/social.module#SocialPageModule' },

  { path: 'speakers/speakers-detail/:id', loadChildren: './pages/speakers-detail/speakers-detail.module#SpeakersDetailPageModule' },
  { path: 'speakers/speakers', loadChildren: './pages/speakers/speakers.module#SpeakersPageModule' },
  { path: 'speakers', redirectTo: "/speakers/speakers" },

  { path: 'update', loadChildren: './pages/update/update.module#UpdatePageModule' },
  
  { path: 'info', loadChildren: './pages/static-page/static-page.module#StaticPagePageModule' },
  { path: 'schedule', loadChildren: './pages/sessions/sessions.module#SessionsPageModule' },
  { path: 'language', loadChildren: './pages/language-change/language-change.module#LanguageChangePageModule' },
  { path: 'conference-select', loadChildren: './pages/conference-select/conference-select.module#ConferenceSelectPageModule' },
  { path: 'more', loadChildren: './pages/menu-more/menu-more.module#MenuMorePageModule' },
  { path: 'chart', loadChildren: './pages/chart/chart.module#ChartPageModule' },
  { path: 'error/:mode', loadChildren: './pages/error/error.module#ErrorPageModule' },
  { path: '**', redirectTo: '/error/404'}
  
]; 
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}