import { Injectable } from '@angular/core';
import { Token } from './token';
import { AppConfigService } from '../app-config.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Logger } from '../logger.service';
import { timeout } from 'rxjs/operators';
import { TokenResponseData } from './interfaces/token-response-data';
import { BehaviorSubject, Observable } from "rxjs";
import { share } from 'rxjs/operators';
const log = new Logger('ClientAuthService');
@Injectable({
  providedIn: 'root'
})
export class ClientAuthService {

  private HTTP_TIMEOUT = 7000;
  private token: Token;
  isAplicationLoggedSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService
  ) {
    log.debug('constructor');
    this.token = undefined;
  }

  /**
   * Domyślne ngłówki źądania api
   * 
   * @param  {string=undefined} accessToken
   * @returns HttpHeaders
   */
  getDefaultHeaders(accessToken: string = undefined): HttpHeaders {
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (accessToken != undefined)
      headers.append('Authorization', 'Bearer ' + accessToken);
    return headers;
  }

  /**
   * Odczyt tokenu z magazynu
   * @returns Promise<Token>
   */
  private async getTokenFromStorage(): Promise<Token> {
    let data = await this.appConfig.appSettings.storage.storageGet('tokens', 'app');
    let token;
    if (data != undefined) {
      log.debug('getTokenFromStorage: token loaded', data);
      token = new Token(data);
      if (token.isValid())
        return token;
      else {
        log.debug('getTokenFromStorage: token loaded but is invalid');
        return undefined;
      }
    }
    log.debug('getTokenFromStorage: no token in storage');
    return data;
  }

  /**
   * Zapis tokenu do magazynu
   * @param token Dane do zapisania
   * @returns Promise
   */
  private saveTokenToStorage(token: Token): Promise<any> {
    return this.appConfig.appSettings.storage.storageSet('tokens', 'app', token.getToken());
  }

  /**
   * Odczyt tokena z serwera (autoryzacja)
   * @returns Promise<Token>
   */
  private async getTokenFromServer(): Promise<Token> {
    let body = {
      grant_type: 'client_credentials',
      client_id: this.appConfig.Config.API.PublicClientId,
      client_secret: this.appConfig.Config.API.PublicClientSecret
    };
    log.debug('getTokenFromServer: sending request', body);

    let url = this.appConfig.Config.API.URL + 'token';
    let headers = { headers: this.getDefaultHeaders() };

    let response = await this.http.post<TokenResponseData>(url, body, headers).pipe(
      timeout(this.HTTP_TIMEOUT)
    ).toPromise();
    let token = new Token(response, true);
    if (token.isValid) {
      log.debug('getTokenFromServer: server returns valid token', token);
      return token;
    }
    log.debug('getTokenFromServer: error requesting token from server', response);
    return undefined;

  }


  /**
   * Loguje aplikację i zwraca token po zalogowaniu
   * lub undefined gdy nie mozna zalogować aplikacji
   * 
   * @param  {boolean=false} forceRequest
   * @returns Promise<Token>
   */
  async login(forceRequest: boolean = false): Promise<Token> {
    log.debug('login: forceRequest=' + forceRequest);
    let token: Token;
    this.token = undefined;
    this.isAplicationLoggedSubject.next(false);
    if (!forceRequest) {
      token = await this.getTokenFromStorage();
      if (token != undefined) {
        this.isAplicationLoggedSubject.next(true);
        log.debug('login: token from storage', token)
        this.token = token;
        return token;
      }
    }
    token = await this.getTokenFromServer();
    log.debug('login: token from server', token)
    if (token != undefined) {
      this.isAplicationLoggedSubject.next(true);
      this.saveTokenToStorage(token);
      this.token = token;
      return token;
    }
    return token;

  }

  /**
   * Zwraca token lub undefined gdy brak / niewazny
   * @returns Token
   */
  getToken(): Token {
    if (!this.token || !this.token.isValid()) {
      this.token = undefined;
      this.isAplicationLoggedSubject.next(false);
    }
    return this.token;
  }


  /**
   * Czy aplikacja jest zalogowana
   * @returns Observable<boolean>
   */
  isLoggedIn(): Observable<boolean> {
    return this.isAplicationLoggedSubject.asObservable().pipe(share());
  }
}
